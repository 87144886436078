import axios from "axios";
import React, { useState } from "react";
import { Modal, Button , Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Alert from './Alert'
import jwt_decode from "jwt-decode";
import Verficationstep from "../2step/Verficationstep";

// import axiosConfig from '../../utils/axiosConfig';
const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
});
const HeaderAdmin = ({adminId,userinfo,show1,setShow1,totalDeposite,benefit,user}) => { 


  // const eachBenefit = benefit.filter(
  //   (element) =>
  //      element.status === "Accept"
  // );

  // let totalBenefit = eachBenefit.reduce(function (accumulator, item) {
  //   return accumulator + item.profit.profitDay;
  // }, 0);
  // console.log(totalBenefit)
   

  const UserAuthorization = localStorage.getItem("token");
  var token = UserAuthorization;
  var decoded = jwt_decode(token);

  var userId2 = decoded.payload._id;
  const [show, setShow] = useState(false);
  const [ready2,setReady2] = useState();
  const [eachverficat,setEachverficat] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
  const [validId,setValidId] = useState({
    userId : '',
    token : ''
  });
  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const [wallet,setWallet] = useState({
    walletAdress : "",
    userId : adminId
  })
    const changeHandler = (e)=>{
        setWallet({
            ...wallet,
            walletAdress : e.target.value
        })
    }

    const changeHandler1 = (e)=>{
      setValidId({
        ...validId,
        userId : eachverficat,
        token : e.target.value
      })
    }

    const handleShow1 = (e) => {
      setShow1(true);
      api.post('api/v1/verfication/userId',{userId : userId2 }).then((resp3)=>{
        console.log(resp3)
        const dataa = resp3.data
        const lastindexData =  dataa[dataa.length - 1]._id;
        console.log(lastindexData)
        setEachverficat(lastindexData)
       
      }).catch((err4)=>{
        console.log(err4)
      })
    };

    const submitHandler = (event)=>{
        event.preventDefault();
        api.put('api/v1/walletAdress', wallet).then((res)=>{
            console.log(res)
            setShow(false)
            setOpen(true);
        }).catch((err)=>{
            console.log(err)
        });
    }
    let history = useHistory();
    const clickHandler = () => {
      history.push({
        pathname: '/verfication',
        state: { userId2 }
    });
    };

    const handleClose1 = () => setShow1(false);
    const cliclHandler2 = (e) => {
      e.preventDefault();
      api
        .post("api/v1/verfication/validate", validId)
        .then((res2) => {
          console.log(res2.data.validate)
          if(res2.data.validate === true){
            setReady2(true)
          }else{
            setReady2(false)
          }
        })
        .catch((err2) => {
          console.log(err2);
        });
        
    };
    
  return (
    <div className="col-12 p-4 headerUser mb-4 ">
      <div className="row justify-content-between">
        <div className="col-4 col-md-3">
          <button className="btn btn-dark text-white w-100" onClick={clickHandler} disabled={userinfo === true ? true : false}> 2 Step Verfication</button>
        </div>

        <div className="col-3">
        <button
            className="btn btn-dark text-white w-100"
            disabled={true}
          >
        Total capital : {totalDeposite.toFixed(2)}
        </button>
        </div>
        <div className="col-4 col-md-3">
          <button
            className="btn btn-dark text-white w-100"
            onClick={handleShow}
          >
            Change the Wallet address
          </button>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change the Wallet address</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Wallet address</Form.Label>
              <Form.Control type="email" placeholder="Enter Wallet address" onChange={changeHandler} />
              <Form.Text className="text-muted">
                Please 2 steps verfication before registering
              </Form.Text>
            </Form.Group>
            <Button variant="warning"  onClick={handleShow1}>
              verfication
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={submitHandler} disabled={ready2 === true ? false : true}>Submit</Button>
        </Modal.Footer>
      </Modal>
      <Alert open={open}  handleClose={handleClose2}  />
      <Verficationstep  show1={show1} handleClose1={handleClose1} ready2={ready2} userInfo={userinfo} changeHandler={changeHandler1} cliclHandler2={cliclHandler2}/>

    </div>
  );
};

export default HeaderAdmin;
