import React from "react";
import { Link } from "react-router-dom";
// import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
// import { SidebarDatauser } from "./SidebarDatauser";
import "./sidebar.css";
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExitBtn from '../ExitBtn/ExitBtn';

// import TopupBtn from '../TopupBtn/TopupBtn';

function Sidebar({sidebar,showSidebar,show1,setShow1,setShowTopup,setShowDeposite,setShowWithdrow,setShowRef,showRef}) {

 

  return (
    <>
    
      {/* <div className="navbar">
        <Link to="#" className="menu-bars">
          <MenuIcon onClick={showSidebar} />
        </Link>
      </div> */}

      <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
              <CloseIcon />
            </Link>
          </li>
          {/* {SidebarDatauser.map((e, i) => {
            return (
              <li key={i} className={e.cName}>
                <Link to={e.path} >
                  {e.icon}
                  <span>{e.title}</span>
                </Link>
              </li>
            );
          })} */}

          <li className="nav-text" onClick={() => setShow1(true)}>
            <span>
            <PhonelinkLockIcon className="mr-2"/>
            Validation
            </span>
          </li>

          <li className="nav-text" onClick={() => setShowTopup(true)}>
            <span>
            <LocalAtmIcon className="mr-2"/>
            Topup
            </span>
          {/* <TopupBtn className="asdf"/> */}
            
          </li>
         
          <li className="nav-text" onClick={() => setShowWithdrow(true)}>
            <span>
            <MoneyOffIcon className="mr-2"/>
            Withdrow
            </span>
          </li>

          <li className="nav-text" onClick={() => setShowDeposite(true)}>
            <span>
            <AttachMoneyIcon className="mr-2"/>
            Deposit
            </span>
          </li>

          <li className="nav-text" onClick={() => setShowRef(true)}>
            <span>
            <PeopleOutlineIcon className="mr-2"/>
            Referral
            </span>
          </li>

          <li className="nav-text exits">
            {/* <span>
            <ExitToAppIcon className="mr-2" />
            Exit
            </span> */}
            <ExitBtn/>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
