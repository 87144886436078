import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import Alert from './Alert'
const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
});

const Remove2step = () => {
    
    const [showNewUser, setshowNewUser] = useState(false);
    const [eachuser, setEachuser] = useState([]);
    const [open, setOpen] = useState(false);

    const UserAuthorization = localStorage.getItem("token");
  

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    useEffect(() => {
      const headers = { Authorization: `Bearer ${UserAuthorization}` };
      api
        .get("api/v1/users", { headers })
        .then((resp) => {
          setEachuser(resp.data);
        })
        .catch((err2) => {
          console.log(err2);
        });
    }, [UserAuthorization]);

    const rebels = eachuser.filter((pilot) => pilot.isAdmin === false);

    // console.log(rebels)
    const submitHandler = (contactId) => {
    
        api
        .post("api/v1/users/deleteVerfication", { userid: contactId })
        .then((res4) => {
          setOpen(true);
          setshowNewUser(false)
        })
        .catch((err3) => {
          console.log(err3);
        });
      
      
    };

    
  

        return (
          <div className="row mx-auto my-3">
            <div className="col-12 px-md-0">
              <button
                className="btn btn-primary text-white"
                onClick={() => setshowNewUser(true)}
              >
                Remove 2Step
              </button>
            </div>

            <Modal
          show={showNewUser}
          onHide={() => setshowNewUser(false)}
          dialogClassName="modal-showNewUser"
          aria-labelledby="modal-showNewUser"
          centered
          size="lg"
        >
          <Modal.Title id="modal-showNewUser" className="p-3">
            Are you sure about Remove 2 Step Verfication User?
          </Modal.Title>
          <Modal.Body>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Email</th>
                  <th>2 Step Verfication</th>
                  <th>Action</th>
                  
                </tr>
              </thead>
              <tbody>
                {rebels.map((e, i) => (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{e.email}</td>
                    <td className="text-center">
                        {e.verfication === false ? (<button className="btn btn-danger text-light" disabled={true}>Inactive</button>) : (<button className="btn btn-success text-light" disabled={true}>Active</button>)}
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => submitHandler(e._id)}
                        disabled={e.verfication === false ? true : false}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className=" justify-content-end ">
            <Button
              onClick={() => setshowNewUser(false)}
              className=" btn-danger"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Alert open={open}  handleClose={handleClose2}  />


            
            {/* <Modal
                    show={showNewUser1}
                    onHide={() => setshowNewUser1(false)}
                    dialogClassName="modal-showNewUser"
                    aria-labelledby="modal-showNewUser"
                    centered
                >
                    <Modal.Title id="modal-showNewUser" className="p-3">
                        Are you sure about Update Password User?
                    </Modal.Title>
                    <Modal.Body>

                
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-nowrap">New Password :</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="PasswordUser" name="password" placeholder="New Password..." onChange={registerHandler}/>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className=" justify-content-between">
                        <button className="btn btn-primary text-white col-7" onClick={submitHandler}>Update Password</button>
                        <Button onClick={() => setshowNewUser(false)} className=" btn-secondary">Close</Button>
                    </Modal.Footer>
                </Modal> */}
            </div>
        
        );
      
    
}



export default Remove2step
