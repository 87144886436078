import axios from 'axios';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';
const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
  })
const TopupBtn = ({result,totalPrice,avalble,showTopup,setShowTopup,roundAllProfit,userId,userAllTopUp,resultLast,topUpMoney,userTopUp}) => {
    // const map1 = userAllTopUp.map(x => x.adminPrice)
    // const sweeterArray = map1.map(sweetItem => {
    //     return sweetItem
    // })
    // const topupbefore = map1[0]
    // console.log("Amin",roundAllProfit)
    // console.log()
    // const [info] = useState({
    //     price :   64.7 ,
    //     comment : "topup",
    //     userId : userId, 
    // });
    // ISODate("2021-12-04T09:14:03.891Z")
    // ISODate("2022-05-12T10:06:51.486Z")
    var lastArr = userTopUp[userTopUp.length - 1];
    if( typeof lastArr == 'undefined' ) {
        var isafter = true;
    }else{
        
        // var x = moment(lastArr.verficationDate).add(1, 'M').calendar();
        var newDate = moment(new Date());
        var itemDate = moment(lastArr.verficationDate);
        var difrenceDay =  newDate.diff(itemDate, 'days');
        if(difrenceDay > 29) {
            isafter = true;
        }else{
            // isafter = moment(new Date()).isAfter(x);\
            isafter = false;
        }
         
        //  console.log(difrenceDay)
    }

   
    console.log(topUpMoney)
    const topupHandler = (e)=>{
        if(roundAllProfit > 0 ){
            api.post('api/v1/history',{ price : roundAllProfit , comment : "topup", userId : userId,}).then((res)=>{
                console.log(res)
                }).catch((err)=>{
                    console.log(err)
                })
                setShowTopup(false)
                // setShowDeposite(false);
                // setOpen(true)
        }else{
            alert('موجودی ناکافی')
        }
        
    }

    if(result){
        
        // var profitMont = totalPrice * result.interestProfit / 30
        // var profitMonthRound = Math.round((profitMont + Number.EPSILON) * 100) / 100
        
    }else{
        console.log("no result")
    }
    return (
        <div className="row mx-auto mb-4">
            <div className="col-12 px-0">
                <button className="btn btn-success2 text-white w-100 py-4" onClick={() => setShowTopup(true)}>Topup</button>
            </div>
            <div className="col-12">
                <Modal
                    show={showTopup}
                    onHide={() => setShowTopup(false)}
                    dialogClassName="modal-Topup"
                    aria-labelledby="modal-Topup"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="modal-Topup">
                        Are you sure about topup?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                        This will update your contract date and you will not be able to use the ( Withdraw all money ) option for three months.
                        </p>
                        <hr />
                        {/* <p className="font-weight-bold col-12 text-center d-inline-block">Amount of interest : {topupbefore < roundAllProfit  ? roundAllProfit - topupbefore : roundAllProfit} $</p> */}
                        <p className="font-weight-bold col-12 text-center d-inline-block">Amount of interest : {topUpMoney} $</p>

                    </Modal.Body>
                    <Modal.Footer  className=" justify-content-between">
                        <button onClick={topupHandler} className="btn btn-success text-white col-7" disabled={roundAllProfit === 0  ? true : false || isafter === true ? false : true}>Topup</button>
                        <Button onClick={() => setShowTopup(false)} className=" btn-secondary">Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>

    )
}

export default TopupBtn
