import React, { Component } from "react";
import Slider from "react-slick";
import './carousel.css'
export default class VerticalMode extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 1500,
      cssEase: "linear",
      
      // beforeChange: function(currentSlide, nextSlide) {
      //   console.log("before change", currentSlide, nextSlide);
      // },
      // afterChange: function(currentSlide) {
      //   console.log("after change", currentSlide);
      // }
    };
    return (
      <div className="sliderDef">
        <h2>Your Fiat Currency</h2>
        <Slider {...settings}>
          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/EU.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/US.svg" alt="" />
              <span>EURUSD</span>
              <span>30%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/GB.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/US.svg" alt="" />
              <span>GBPUSD</span>
              <span>18%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/US.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/JP.svg" alt="" />
              <span>USDJPY</span>
              <span>27%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/NZ.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/US.svg" alt="" />
              <span>NZDUSD</span>
              <span>7%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/NZ.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/JP.svg" alt="" />
              <span>NZDJPY</span>
              <span>20%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/NZ.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/CA.svg" alt="" />
              <span>NZDCAD</span>
              <span>15%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/NZ.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/CH.svg" alt="" />
              <span>NZDCHF</span>
              <span>28%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/US.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/CA.svg" alt="" />
              <span>USDCAD</span>
              <span>5%</span>
          </div>

          <div className="icon1">
              <img src="" alt="https://s3-symbol-logo.tradingview.com/country/US.svg" />
              <img src="https://s3-symbol-logo.tradingview.com/country/CH.svg" alt="" />
              <span>USDCHF</span>
              <span>2%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/CA.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/JP.svg" alt="" />
              <span>CADJPY</span>
              <span>11%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/CA.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/CH.svg" alt="" />
              <span>CADCHF</span>
              <span>3%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/AU.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/US.svg" alt="" />
              <span>AUDUSD</span>
              <span>5%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/country/GB.svg" alt="" />
              <img src="https://s3-symbol-logo.tradingview.com/country/CA.svg" alt="" />
              <span>GBPCAD</span>
              <span>2%</span>
          </div>
          
        </Slider>
      </div>
    );
  }
}