import React , { useState} from 'react'
import { Form, Button  } from 'react-bootstrap';
import './FormLogin.css';
import axios from 'axios'
import Validation from './Validation'
import MyReCaptcha from '../ReCaptcha/MyReCaptcha';
import Alert from './Alert'
// import axiosConfig from '../../utils/axiosConfig';
// import https from 'https'

const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
});

const FormLogin = ({login,setLogin,setisLogin}) => {

    const [open, setOpen] = useState(false);
    
    // const agent = new https.Agent({  
    //     rejectUnauthorized: false
    //   });
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const[error,setError] = useState({})
    const LoginHandler = (e) => {
        setLogin({
            ...login,
            [e.target.name] : e.target.value
        })
    }
    const [expired,setExpired] = useState(false);
    
    const submitLoginHandler = (e) => {
        e.preventDefault();
        setError(Validation(login));
       
        if(Object.keys(error).length === 0 && login.email && login.password  && expired ){
            api.post('api/v1/users/login',login)
            .then((response)=>{
                localStorage.setItem('token',response.data.token)
                setisLogin(true)
            }).catch((error)=>{
                console.log(error.response.data.message);
                setOpen(true);
            })
        }
        }

        
        
    

   
    return (
        <Form className=" my-1 my-md-4 px-md-5">
            <Form.Group controlId="formBasicEmail">
                <Form.Label className="form-def3">Email address</Form.Label>
                <Form.Control className="form-def" type="email" value={login.email} name="email" placeholder="Enter your email.." onChange={LoginHandler} required />
                {error.email && <small className="error">{error.email}</small>}
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
                <Form.Label className="form-def3">Password</Form.Label>
                <Form.Control type="password" value={login.password} name="password" placeholder="Enter your password.." onChange={LoginHandler} required/>
                {error.password && <small className="error">{error.password}</small>}
            </Form.Group>
            <MyReCaptcha setExpired={setExpired} />
            {/* <Button className="btn btn-danger" onClick={handleShow1}>2Step verfication</Button> */}
            <Button className="btndef btn-block " type="submit" onClick={submitLoginHandler}>
                Login
            </Button>




            <Alert open={open}  handleClose={handleClose}  />

        </Form>
    )
}

export default FormLogin
