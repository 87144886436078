import axios from 'axios';
import React, {  useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
// import moment from 'moment';
import jwt_decode from "jwt-decode";
import Alert from '../Alert/Alert';
const Refferal = ({showRef,setShowRef,userId}) => {
    const UserAuthorization = localStorage.getItem("token");
    var token = UserAuthorization;
    var decoded = jwt_decode(token);
    const userEmail = decoded.payload.email;
    const RefEMAIL = decoded.payload.refEmail;
    const RefSTATUS = decoded.payload.refStatus
    console.log(RefSTATUS)
    const api = axios.create({
        baseURL : `https://api.myinfinitycapital.com/`
      });
    
    const [message, setMessage] = useState('');
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);

    const [alertes,setAlertes] = useState({
        severity : "",
        title : ""
    });
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
      }

      const handleChange = event => {
        if (!isValidEmail(event.target.value)) {
          setError('Email is invalid');
        } else {
          setError(null);
        }
    
        setMessage(event.target.value);
      };

      const saveHandler = () => {
        if(message === userEmail){
            setAlertes({
                severity : "error",
                title : "Wrong Email !"
            })
            setOpen(true)
            setShowRef(false)
        }else{
            api.post('api/v1/referral/newReferral',{ email : message , personId : userId }).then((res)=>{
               
                setAlertes({
                    severity : "success",
                    title : res.data.msg
                })
                setOpen(true);
                setShowRef(false)
                }).catch((err)=>{
                    console.log(err)
                })
                
          
        }
        }

        const handleClose2 = (event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
        
            setOpen(false);
          };
    
  return (
    <div className="row mx-auto my-3">
    <div className="col-12 px-0">
        <button className="btn btn-success3 text-white w-100 py-4 " onClick={() => setShowRef(true)}>Referral</button>
    </div>
    <div className="col-12">
        <Modal
            show={showRef}
            onHide={() => setShowRef(false)}
            dialogClassName="modal-Withdrow"
            aria-labelledby="modal-Withdrow"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="modal-Withdrow">
                Write your referral email.
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="col-12 d-flex flex-column align-items-center px-0">
                    <div className="form-group row w-100">
                        <label for="walletAddress" className="col-sm-2 col-form-label text-nowrap">Email :</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" name='message' id="message"  value={message} onChange={handleChange} placeholder={RefEMAIL ? RefEMAIL: "Email address..."} />
                            {error && <h2 style={{color: 'red',fontSize : '14px', marginTop : "10px"}}>{error}</h2>}
                        </div>
                    </div>
                </form>
                
                
            </Modal.Body>
            <Modal.Footer closeButton className=" justify-content-between">
                <Button  className="btn btn-success text-white col-7"  onClick={saveHandler} disabled={RefSTATUS ? true : false}>Save</Button>
                <Button onClick={() => setShowRef(false)} className=" btn-secondary">Close</Button>
            </Modal.Footer>
        </Modal>

    </div>
    <Alert alertes={alertes} handleClose2={handleClose2} open={open}/>
</div>
)
}

export default Refferal