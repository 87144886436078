import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import axios from 'axios'
// import axiosConfig from '../../utils/axiosConfig';

const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
})

const ConfirmBtn = ({ eachHistory , setLoading }) => {
  

  const [showConfirm, setshowConfirm] = useState(false);
  const [pricee, setPrice] = useState({
    id : eachHistory._id,
    price: eachHistory.adminPrice,
    status: eachHistory.status,
    interestProfit :eachHistory.interestProfit,
    userId : eachHistory.userId._id
  });

  // const [profitt, setProfitt] = useState({
  //   id : eachHistory._id,
  //   interestProfit: eachHistory.interestProfit
   
  // });

  // console.log(profitt)

  const changeHandler = (e) => {
    setPrice({
      ...pricee,
      status: e.target.value,
    });
  };

  const PriceHandler = (e) => {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setPrice({
        ...pricee,
        price: Number(e.target.value),
      });
    }
  };

  const profitHandler = (e) => {  
    var a = e.target.value;
    setPrice({  
        ...pricee,
        interestProfit: parseFloat(a),
      });
    
  };

  const submitHandler = ()=>{
    api.put('api/v1/history/update', pricee).then((response)=>{
        // console.log(response)
        setshowConfirm(false)
        setLoading(true)
    }).catch((err)=>{
        console.log(err)
    })
  }

//   useEffect(()=>{
//     axios.put('http://localhost:3000/api/v1/history/update', pricee);
//   },[])

//  console.log(pricee)

  return (
    <div>
      <button
        className="btn btn-danger text-white w-100 text-center p-2 rounded-pill d-block"
        onClick={() => setshowConfirm(true)}
      >
        Edit
      </button>
      <div className="col-12">
        <Modal
          show={showConfirm}
          onHide={() => setshowConfirm(false)}
          dialogClassName="modal-Edit"
          aria-labelledby="modal-Edit"
          centered
        >
          <Modal.Header >
            <Modal.Title id="modal-Edit">
              Are you sure about confirm?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="col-12 d-flex flex-column  ">
              {/* <div className="form-group row">
                <label
                  
                  className="col-sm-6 col-form-label text-nowrap"
                >
                  از آدرس ولت :
                </label>
                <label
                  
                  className="col-sm-6 col-form-label text-nowrap"
                >
                  123456789
                </label>
              </div> */}
              <div className="form-group row">
                <p
                  
                  className="col-sm-4 col-form-label text-nowrap"
                >
                  Money :
                </p>
                <p
                  
                  className="col-sm-8 col-form-label text-nowrap"
                >
                  {eachHistory.adminPrice} $
                </p>
              </div>
              <div className="form-group row">
                <label
                  
                  className="col-sm-4 col-form-label text-nowrap"
                >
                  Deposit date :
                </label>
                <label
                  
                  className="col-sm-8 col-form-label text-nowrap"
                >
                  {moment(eachHistory.createdAt).format("DD/MM/YYYY")}
                </label>
              </div>
              <div className="form-group row">
                <label
                  
                  className="col-sm-4 col-form-label text-nowrap"
                >
                  Tracking Code :
                </label>
                <label
                  
                  className="col-sm-8 col-form-label text-nowrap"
                >
                  {eachHistory.trackingCode
                    ? eachHistory.trackingCode
                    : "Tracking Code is not entered"}
                </label>
              </div>

              <div className="form-group row">
                <label
                  
                  className="col-sm-4 col-form-label text-nowrap"
                >
                  Change profit :
                </label>
                <label
                  
                  className="col-sm-8 col-form-label text-nowrap"
                >
                  <input
                    type="number"
                    className="form-control"
                    id="profittAddress"
                    value={pricee.interestProfit}
                    onChange={profitHandler}
                  />
                </label>
              </div>
              <div className="form-group row">
                <label
                  
                  className="col-sm-5 col-form-label text-nowrap"
                >
                  Approved amount :
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="walletAddress"
                    value={pricee.price}
                    onChange={PriceHandler}
                    placeholder="Approved amount :"
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  
                  className="col-sm-4 col-form-label text-nowrap"
                >
                  Condition :
                </label>
                <div className="col-sm-8">
                  <select
                    className="form-control text-nowrap"
                    onChange={changeHandler}
                  >
                    <option
                      className="text-nowrap"
                      name="accept"
                      value="Accept"
                    >
                      Accepted
                    </option>
                    <option
                      className="text-nowrap"
                      name="pending"
                      value="Pending"
                    >
                      Pendding
                    </option>
                    <option
                      className="text-nowrap"
                      name="reject"
                      value="Reject"
                    >
                      Reject
                    </option>
                  </select>
                </div>
              </div>

              {/* <button className="btn btn-warning text-white col-12 col-md-6">
                Validation
              </button> */}
            </form>
          </Modal.Body>
          <Modal.Footer  className=" justify-content-between">
            <button className="btn btn-success text-white col-7" onClick={submitHandler}>Save</button>
            <Button
              onClick={() => setshowConfirm(false)}
              className=" btn-danger"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ConfirmBtn;
