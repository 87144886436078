import React,{useEffect, useState} from 'react';
import './App.css';
// import { Container, Row, Col } from 'react-bootstrap';
import Login from '../src/containers/Login/Login'
import Panel from '../src/containers/Panel/Panel'
import AdminPanel from './containers/AdminPanel/AdminPanel';
import jwt_decode from "jwt-decode";
import Verficationmain from './containers/2stepverfic/Verficationmain';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Verfication from './containers/verfication/Verfication';
import axios from 'axios';
// import UserInfo from './components/useriInfo/UserInfo';


function App() {
  const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
  });
  const [userinfo,setUserinfo] = useState(false)
  const [isAdmin,setIsAdmin] = useState(false)

  const [show1, setShow1] = useState(false);
  const [isLogin,setisLogin] = useState(false);
  const [eachverficat,setEachverficat] = useState('');
  const [ready2,setReady2] = useState()
  const [validId,setValidId] = useState({
    userId : '',
    token : ''
  });
  const UserAuthorization = localStorage.getItem("token");
  var tokenn = UserAuthorization;
  // var decodedd = jwt_decode(tokenn);

  // var userId2 = decodedd.payload._id;
  // const storage = localStorage.getItem('token');
  // var token = UserAuthorization;
  if(UserAuthorization){
    var decodedd = jwt_decode(tokenn);
    var userId2 = decodedd.payload._id;
    // console.log(userId2)
    // var decoded = jwt_decode(token);
    // var isAdmin = decodedd.payload.isAdmin
  }else{
    console.log('no token');
  };

  useEffect(()=>{
    // localStorage.clear();
 
    if(isLogin === true){
      api
      .post("api/v1/users/userid", { id : userId2 })
      .then((respo) => {
        const data2 = respo.data
        const eachuserInfo = data2.find(element => element._id === userId2 );
      
        setUserinfo(eachuserInfo.verfication);
        setIsAdmin(eachuserInfo.isAdmin)
      })
      .catch((error2) => {
        console.log(error2);
      });
    }
    
  },[api,userId2,isLogin])

  const handleClose1 = () => setShow1(false);

  // console.log(userinfo)
  
  const handleShow1 = () => {
    setShow1(true);
    api.post('api/v1/verfication/userId',{userId : userId2 }).then((resp3)=>{
      // console.log(resp3)
      const dataa = resp3.data
      const lastindexData =  dataa[dataa.length - 1]._id;
      setEachverficat(lastindexData)
     
    }).catch((err4)=>{
      console.log(err4)
    })
  };
 
  const cliclHandler2 = (e) => {
    e.preventDefault();
    api
      .post("api/v1/verfication/validate", validId)
      .then((res2) => {
        console.log(res2.data.validate)
        if(res2.data.validate === true){
          setReady2(true)
        }else{
          setReady2(false)
        }
      })
      .catch((err2) => {
        console.log(err2);
      });
      
  };

  const changeHandler = (e)=>{
    setValidId({
      ...validId,
      userId : eachverficat,
      token : e.target.value
    })
  }

  // console.log(userinfo)

  return (
    <Router>
       <div className="App">
       <Switch>
              <Route path="/verfication">
                    <Verfication/>
              </Route>
              
              <Route path="/" exact>
              {!isLogin  ? <Login setisLogin={setisLogin} /> : userinfo === false && isAdmin === false  ? <Panel userInfo={userinfo}/> : userinfo === false && isAdmin === true ? <AdminPanel userinfo={userinfo}/> :  <Verficationmain show1={show1} handleClose1={handleClose1} ready2={ready2} userInfo={userinfo} changeHandler={changeHandler} cliclHandler2={cliclHandler2} handleShow1={handleShow1} />   }
              </Route>
      </Switch>
    </div>
    {/* isAdmin === true ?  <AdminPanel/> : <Panel/> */}
    </Router>
   
  );
  
}

export default App;
