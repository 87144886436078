import React, { Component } from "react";
import Slider from "react-slick";
import './carousel.css'
export default class VerticalMode extends Component {
  render() {
    const settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      vertical: true,
      verticalSwiping: true,
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 1500,
      cssEase: "linear",
      
      // beforeChange: function(currentSlide, nextSlide) {
      //   console.log("before change", currentSlide, nextSlide);
      // },
      // afterChange: function(currentSlide) {
      //   console.log("after change", currentSlide);
      // }
    };
    return (
      <div className="sliderDef">
        <h2>Your Digital Currency</h2>
        <Slider {...settings}>
          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCBTC.svg" alt="" />
              <span>BTC</span>
              <span>30%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCETH.svg" alt="" />
              <span>ETH</span>
              <span>18%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCBNB.svg" alt="" />
              <span>BNB</span>
              <span>27%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCSHIB.svg" alt="" />
              <span>SHIB</span>
              <span>7%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCSAND.svg" alt="" />
              <span>SAND</span>
              <span>20%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCDOG.svg" alt="" />
              <span>DOG</span>
              <span>15%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCDASH.svg" alt="" />
              <span>DASHUSD</span>
              <span>28%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCAAVE.svg" alt="" />
              <span>AAVE</span>
              <span>5%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCXTZ.svg" alt="" />
              <span>XTZ</span>
              <span>21%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCGRAPH.svg" alt="" />
              <span>GRT</span>
              <span>17%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCMINA.svg" alt="" />
              <span>MINA</span>
              <span>11%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCHBAR.svg" alt="" />
              <span>HBAR</span>
              <span>6%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCDOT.svg" alt="" />
              <span>DOT</span>
              <span>4</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCCELR.svg" alt="" />
              <span>CELR</span>
              <span>2%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCSOL.svg" alt="" />
              <span>SOL</span>
              <span>17%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCAVAX.svg" alt="" />
              <span>AVAX</span>
              <span>10%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCCRV.svg" alt="" />
              <span>CRV</span>
              <span>3%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCICP.svg" alt="" />
              <span>ICP</span>
              <span>5%</span>
          </div>

          <div className="icon1">
              <img src="https://s3-symbol-logo.tradingview.com/crypto/XTVCTRX.svg" alt="" />
              <span>TRX</span>
              <span>2%</span>
          </div>
          
        </Slider>
      </div>
    );
  }
}