import React,{ useState } from 'react'
import {  Modal, Button  } from "react-bootstrap";
import iran from '../../assets/img/iran.svg'
import iraq from '../../assets/img/unitedarabemirates.svg'
import unitedkingdom from '../../assets/img/unitedkingdom.svg'

import './HeaderUser.css'


const ModalHeaderEn = ({handleClose,clickHandler,userInfo,show}) => {
  const [flag,setFlag] = useState({
    uk : true,
    iq : false,
    ir : false
  })

  console.log(flag)
  return (
    <Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    dialogClassName="modal-90w"
    size="xl"
  >
    {flag.uk === true ? 
    
      <>
    <Modal.Header closeButton>
      <div className='flagIr ml-3 ' onClick={() => setFlag({uk : true})}><img src={unitedkingdom} alt="unitedkingdom" width="100%" /></div>
      <div className='flagIr mx-3' onClick={() => setFlag({iq : true})}><img src={iraq} alt="iraq" width="100%" /></div>
      <div className='flagIr' onClick={() => setFlag({ir : true})}><img src={iran} alt="iran" width="100%" /></div>
      <Modal.Title className='textCnter'>Terms and Conditions</Modal.Title>

    </Modal.Header>
    
    <Modal.Body>
      <strong>Main:</strong>
      <br />
      <br />
      1 There are some risks involved, So be aware to taking any
      responsibilities of that.
      <br />
      <br />
      2 The minimum investment is 1000 Tether received only in TRC20.
      <br />
      <br />
      3 The duration of our contract is a one year and started from the
      confirmation of your account by our team, notice that if the capital
      is not withdrawn, it will be automatically renewed in the end of the
      contract.
      <br />
      <br />
      4 The profit of your investment will be deposited to the Tether
      address you entered when you registered. So be careful to enter the
      address correctly.
      <br />
      <br />
      <strong>Details:</strong>
      <br />
      <br />
      1 You will be allowed to withdraw the interest monthly.
      <br />
      <br />
      2 You can top up your capital by saving your profit.
      <br />
      <br />
      3 You will not have any restriction or limitation to raising your
      capital.
      <br />
      <br />
      4 After the capital increasing(top up/deposits) your contract
      automatically will become a new one from that moment.
      <br />
      <br />
      5 you will not allowed to withdraw your capital for less than 3
      months, after that if you withdraw the whole capital, the 3 months
      profit will be deducted from your original money.
      <br />
      <br />
      6 In case of capital increase, your interest of your previous contract
      will be calculated up to that point and will be paid together with the
      profit of the new contract.
      <br />
      <br />
      7 Your capital must be a factor of 50, In case of non-compliance your
      profit will be calculated automatically from the first step down,
      which has a factor of 50.
      <br />
      <br />
      8 Please pay attention to the Tether price and transferring fee.
      <br />
      <br />
      <strong>Security:</strong>
      <br />
      1 Don’t save your Username and password.
      <br />2 Be sure to activate your two step confirmation.
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={handleClose} disabled={userInfo === true ? false : true}>
        Close
      </Button>
      <Button variant="primary" onClick={clickHandler} disabled={userInfo === true ? true : false} >
        2 Step Verfication
      </Button>
    </Modal.Footer>
    </>
    : flag.iq === true ? 

    <>
    <Modal.Header closeButton>
      
      <div className='flagIr ml-3' onClick={() => setFlag({uk : true})}><img src={unitedkingdom} alt="unitedkingdom" width="100%" /></div>
      <div className='flagIr mx-3' onClick={() => setFlag({iq : true})}><img src={iraq} alt="iraq" width="100%" /></div>
      <div className='flagIr' onClick={() => setFlag({ir : true})}><img src={iran} alt="iran" width="100%" /></div>
      <Modal.Title className='textCnter'>الأحكام والشروط</Modal.Title>
    </Modal.Header>
    
    <Modal.Body className='dirDef'>
      <strong>رئيسي:</strong>
      <br />
      <br />
      1 هناك بعض المخاطر التي ينطوي عليها الأمر ، لذا كن على دراية بتحمل أي مسؤوليات في ذلك.
      <br />
      <br />
      2 الحد الأدنى للاستثمار هو 1000 تيثر تم استلامها فقط في TRC20.
      <br />
      <br />
      3 مدة عقدنا سنة واحدة وتبدأ من تأكيد حسابك من قبل فريقنا ، لاحظ أنه إذا لم يتم سحب رأس المال ، فسيتم تجديده تلقائيًا في نهاية العقد.      <br />
      <br />
      4 سيتم إيداع ربح استثمارك في عنوان Tether الذي أدخلته عند التسجيل. لذا كن حذرًا لإدخال العنوان بشكل صحيح.      <br />
      <br />
      <strong>تفاصيل:</strong>
      <br />
      <br />
      1 سيُسمح لك بسحب الفائدة شهريًا.
      <br />
      <br />
      2 يمكنك زيادة رأس المال الخاص بك عن طريق توفير أرباحك.
      <br />
      <br />
      3 لن يكون لديك أي قيد أو قيود على زيادة رأس المال الخاص بك.
      <br />
      <br />
      4 بعد زيادة رأس المال (زيادة / إيداعات) سيصبح عقدك تلقائيًا جديدًا منذ تلك اللحظة.
      <br />
      <br />
      5 لن يسمح لك بسحب رأس مالك لمدة تقل عن 3 أشهر ، وبعد ذلك إذا قمت بسحب رأس المال بالكامل ، فسيتم خصم ربح 3 أشهر من أموالك الأصلية.
      <br />
      <br />
      6 في حالة زيادة رأس المال ، سيتم احتساب الفائدة على عقدك السابق حتى تلك النقطة وسيتم دفعها مع ربح العقد الجديد.
      <br />
      <br />
      7 يجب أن يكون رأس المال الخاص بك عامل 50 ، في حالة عدم الامتثال ، سيتم احتساب ربحك تلقائيًا من الخطوة الأولى إلى أسفل ، والتي لها عامل 50.      <br />
      <br />
      8 يرجى الانتباه إلى سعر الحبل ورسوم التحويل.
      <br />
      <br />
      <strong>حماية:</strong>
      <br />
      1 لا تحفظ اسم المستخدم وكلمة المرور.
      <br />
      2 تأكد من تنشيط التأكيد المكون من خطوتين.
      </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={handleClose} disabled={userInfo === true ? false : true}>
        Close
      </Button>
      <Button variant="primary" onClick={clickHandler} disabled={userInfo === true ? true : false} >
        2 Step Verfication
      </Button>
    </Modal.Footer>
    </>

    :
    flag.ir === true ? 
    <>
    <Modal.Header closeButton>
      <div className='flagIr ml-3' onClick={() => setFlag({uk : true})}><img src={unitedkingdom} alt="unitedkingdom" width="100%" /></div>
      <div className='flagIr mx-3' onClick={() => setFlag({iq : true})}><img src={iraq} alt="iraq" width="100%" /></div>
      <div className='flagIr' onClick={() => setFlag({ir : true})}><img src={iran} alt="iran" width="100%" /></div>
      <Modal.Title className='textCnter'>شرایط و ضوابط</Modal.Title>

    </Modal.Header>
    
    <Modal.Body className='dirDef' >
      <strong>اصلی:</strong>
      <br />
      <br />
      1  در این بازار خطراتی وجود دارد، بنابراین مراقب‌ باشید که مسئولیت آن برعهده شماست.
        <br />
      <br />
      2 حداقل سرمایه گذاری 1000 تتر است که فقط از طریق TRC20 دریافت می شود. 
      <br />
      <br />
      3 مدت قرارداد ما یک ساله است و از تایید اکانت شما توسط تیم ما شروع می شود، توجه داشته باشید که در صورت عدم برداشت سرمایه در پایان قرارداد به صورت خودکار تمدید می شود.
      <br />
      <br />
      4 سود سرمایه گذاری شما به آدرس Tether که هنگام ثبت نام وارد کرده اید واریز می شود. پس مراقب باشید که آدرس را به درستی وارد کنید.
      <br />
      <br />
      <strong>جزئیات:</strong>
      <br />
      <br />
      1 شما مجاز به برداشت سود ماهانه خواهید بود.
      <br />
      <br />
      2 با پس انداز سود می توانید سرمایه خود را افزایش دهید.
      <br />
      <br />
      3 هیچ محدودیتی برای افزایش سرمایه خود نخواهید داشت
      <br />
      <br />
      4 پس از افزایش سرمایه قرارداد شما به طور خودکار از آن لحظه به یک قرارداد جدید تبدیل می شود.
      <br />
      <br />
      5  در زمان کمتر از 3 ماه مجاز به برداشت سرمایه خود نخواهید بود، پس از آن در صورت برداشت کل سرمایه، سود 3 ماهه از پول اصلی شما کسر می شود.
      <br />
      <br />
      6 در صورت افزایش سرمایه سود قرارداد قبلی شما تا آن لحظه محاسبه و به همراه سود قرارداد جدید پرداخت خواهد شد.
      <br />
      <br />
      7 سرمایه شما باید ضریب 50 باشد، در صورت عدم رعایت سود شما به صورت خودکار از اولین پله پایین که ضریب 50 دارد محاسبه می شود.
      <br />
      <br />
      8 لطفاً به قیمت تتر و هزینه انتقال توجه کنید.
      <br />
      <br />
      <strong>امنیت:</strong>

      <br />
      1 نام کاربری و رمز عبور خود را ذخیره نکنید.
      <br />
      2 حتما تاییدیه دو مرحله ای خود را فعال کنید.
    </Modal.Body>
    <Modal.Footer>
      <Button variant="danger" onClick={handleClose} disabled={userInfo === true ? false : true}>
        Close
      </Button>
      <Button variant="primary" onClick={clickHandler} disabled={userInfo === true ? true : false} >
        2 Step Verfication
      </Button>
    </Modal.Footer>
    </>
    :
    null

    }
  </Modal>

  )
}

export default ModalHeaderEn