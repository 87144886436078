// import axios from "axios";
import React from "react";
import TableAdmin from "../TableAdmin/TableAdmin";
// import ExitBtn from '../ExitBtn/ExitBtn';
// import NewUserBtn from '../NewUserBtn/NewUserBtn'
// import axiosConfig from '../../utils/axiosConfig';
// const api = axios.create({
//   baseURL : `https://api.myinfinitycapital.com/`
// });

const MainAdmin = ({history,user,benefit,setLoading}) => {


  return (
    <div className="col-12">
      <div className="row">
        <TableAdmin user={user} history={history} benefit={benefit} setLoading={setLoading} />
      </div>
    </div>
  );
};

export default MainAdmin;
