import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
})

const Deleteuser = () => {
  const [showNewUser, setshowNewUser] = useState(false);
  const [eachuser, setEachuser] = useState([]);
  const [reload, setReload] = useState(false);
  const UserAuthorization = localStorage.getItem("token");
  useEffect(() => {
    const headers = { Authorization: `Bearer ${UserAuthorization}` };
    api
      .get("api/v1/users", { headers })
      .then((resp) => {
        setEachuser(resp.data);
      })
      .catch((err2) => {
        console.log(err2);
      });
  }, [UserAuthorization, reload]);

  const rebels = eachuser.filter((pilot) => pilot.isAdmin === false);
  const deleteHandler = (contactId) => {
    console.log(contactId);
    // const headers = { Authorization: `Bearer ${UserAuthorization}` };
    api
      .post("api/v1/users/deleteUser", { id: contactId })
      .then((res4) => {
        console.log(res4);
        setReload(true);
        const newContacts = [...rebels];
        const index2 = rebels.findIndex((contact) => contact._id === contactId);

        newContacts.splice(index2, 1);
        setEachuser(newContacts);
      })
      .catch((err3) => {
        console.log(err3);
      });
  };

  return (
    <div className="row mx-auto my-3">
      <div className="col-12 px-md-0">
        <button
          className="btn btn-primary text-white"
          onClick={() => setshowNewUser(true)}
        >
          Delete User
        </button>
      </div>
      <div className="col-12">
        <Modal
          show={showNewUser}
          onHide={() => setshowNewUser(false)}
          dialogClassName="modal-showNewUser"
          aria-labelledby="modal-showNewUser"
          centered
        >
          <Modal.Title id="modal-showNewUser" className="p-3">
            Are you sure about Update User?
          </Modal.Title>
          <Modal.Body>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {rebels.map((e, i) => (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{e.email}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => deleteHandler(e._id)}
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className=" justify-content-end ">
            <Button
              onClick={() => setshowNewUser(false)}
              className=" btn-secondary"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Deleteuser;
