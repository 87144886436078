import axios from 'axios';
import React, {  useState } from 'react'
import { useEffect } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import jwt_decode from "jwt-decode";
const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
  });
const ReferralAdmin = ({refModal,setRefModal}) => {
    const UserAuthorization = localStorage.getItem("token");
    var token = UserAuthorization;
    var decoded = jwt_decode(token);
   
    var userId = decoded.payload._id;
    const [ref,setRef] = useState([])
    const [sprofit,setSprofit] = useState(0)
      const FetchHandler = () => {
            api.get('api/v1/referral').then((res)=>{
                // console.log("asmsin",res.data)
                setRef(res.data)
                }).catch((err)=>{
                    console.log(err)
                })
        }

        useEffect(() => {
            FetchHandler()
        },[])

        const changeHandler = (e) => {
            setSprofit(e.target.value)
        }

        const sumbitHnadler = () => {
            api.post(`api/v1/history`,{comment : 'referral',status : 'Accept',userId : userId,referralBenfit : Number(sprofit),userPrice : Number(sprofit),adminPrice : Number(sprofit) , interestProfit : 0 }).then((res)=>{
                console.log("ina",res)
                // setRef(res.data)
                }).catch((err)=>{
                    console.log(err)
                })
        }

  return (
    <div className="row mx-auto my-3">
    <div className="col-12 px-0">
        <button className="btn  text-white w-100  " onClick={() => setRefModal(true)}>Referral</button>
    </div>
    <div className="col-12">
        <Modal
            show={refModal}
            onHide={() => setRefModal(false)}
            dialogClassName="modal-Withdrow"
            aria-labelledby="modal-Withdrow"
            centered
            size='lg'
        >
            <Modal.Header closeButton>
                <Modal.Title id="modal-Withdrow">
                All Referral Items : 
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>User</th>
                        <th>Referral</th>
                        <th>Profit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ref.map((e,index)=> (
                             <tr key={index}>
                             <td>{index}</td>
                             <td>{e.personId.email}</td>
                             <td>{e.email}</td>
                             <td>
                                <input type="text" onChange={changeHandler}/>
                             </td>
                             </tr>
                        ))}
                       
                       
                    </tbody>
                </Table>
                
                
            </Modal.Body>
            <Modal.Footer closeButton className=" justify-content-between">
                <Button  className="btn btn-success text-white col-7" onClick={sumbitHnadler} >Save</Button>
                <Button onClick={() => setRefModal(false)} className=" btn-secondary">Close</Button>
            </Modal.Footer>
        </Modal>

    </div>
</div>

  )
}

export default ReferralAdmin