import React from 'react'
import { Link } from "react-router-dom";
// import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
// import { SidebarDataadmin } from "./SidebarDataadmin";
import "./sidebar.css";
import ExitBtn from '../ExitBtn/ExitBtn';
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import NewUserBtn from '../NewUserBtn/NewUserBtn'
import Deleteuser from '../deleteUser/Deleteuser';
import UpdateUser from '../updateUser/UpdateUser'
import Remove2step from '../remove2step/Remove2step';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import LockIcon from '@material-ui/icons/Lock';
import ReferralAdmin from '../ReferralAdmin/ReferralAdmin';
const Sidebaradmin = ({sidebar,showSidebar,refModal,setRefModal}) => {
    return (
        <div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
        <ul className="nav-menu-items">
          <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
              <CloseIcon />
            </Link>
          </li>
          {/* {SidebarDatauser.map((e, i) => {
            return (
              <li key={i} className={e.cName}>
                <Link to={e.path} >
                  {e.icon}
                  <span>{e.title}</span>
                </Link>
              </li>
            );
          })} */}

        

          <li className="nav-text" >
            <span className="abs">
            <PersonAddIcon className="mr-2"/>
            <NewUserBtn/>
            </span>
          {/* <TopupBtn className="asdf"/> */}
            
          </li>

          <li className="nav-text " >
            <span className="abs">
            <PersonAddDisabledIcon className="mr-2"/>
            <Deleteuser/>
            </span>
          {/* <TopupBtn className="asdf"/> */}
            
          </li>

          <li className="nav-text " >
            <span className="abs">
            <LockIcon className="mr-2"/>
            <UpdateUser />
            </span>
          {/* <TopupBtn className="asdf"/> */}
            
          </li>

          <li className="nav-text " >
            <span className="abs">
            <PhonelinkLockIcon className="mr-2"/>
            <Remove2step />
            </span>
          {/* <TopupBtn className="asdf"/> */}
            
          </li>
         
          <li className="nav-text" >
            <span>
            <PhonelinkLockIcon className="mr-2"/>
            <ReferralAdmin refModal={refModal} setRefModal={setRefModal}/>
            </span>
          </li>
 {/*
          <li className="nav-text" onClick={() => setShowDeposite(true)}>
            <span>
            <AttachMoneyIcon className="mr-2"/>
            Deposite
            </span>
          </li> */}

          <li className="nav-text exits">
            {/* <span>
            <ExitToAppIcon className="mr-2" />
            Exit
            </span> */}
            <ExitBtn/>
          </li>
        </ul>
      </nav>
        </div>
    )
}

export default Sidebaradmin
