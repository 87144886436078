import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./HeaderUser.css";
// import gg from "../../assets/img/gogle.png";
import jwt_decode from "jwt-decode";
import Verficationstep from "../2step/Verficationstep";
import ModalHeaderEn from "./ModalHeaderEn";
const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
});
const HeaderUser = ({userInfo,show1,setShow1,}) => {
  const UserAuthorization = localStorage.getItem("token");
  var token = UserAuthorization;
  var decoded = jwt_decode(token);

  var userId2 = decoded.payload._id;
  const [ready2,setReady2] = useState()
  const [show, setShow] = useState(false);

  const [eachverficat,setEachverficat] = useState('');
  const [validId,setValidId] = useState({
    userId : '',
    token : ''
  });
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => {
    setShow1(true);
    api.post('api/v1/verfication/userId',{userId : userId2 }).then((resp3)=>{
      const dataa = resp3.data
      const lastindexData =  dataa[dataa.length - 1]._id;
      setEachverficat(lastindexData)
     
    }).catch((err4)=>{
      console.log(err4)
    })
  };
  let history = useHistory();
  const clickHandler = () => {
    history.push({
      pathname: '/verfication',
      state: { userId2 }
  });
  };


  useEffect(()=>{
    if(userInfo === false){
      setShow(true)
    }
  },[userInfo])
  
  const changeHandler = (e)=>{
    setValidId({
      ...validId,
      userId : eachverficat,
      token : e.target.value
    })
  }

  const cliclHandler2 = (e) => {
    e.preventDefault();
    api
      .post("api/v1/verfication/validate", validId)
      .then((res2) => {
        if(res2.data.validate === true){
          setReady2(true)
        }else{
          setReady2(false)
        }
      })
      .catch((err2) => {
        console.log(err2);
      });
      
  };


  return (
    <>
      <Col xs className="p-4 headerUser ">
        <div className="row justify-content-between">
          <div className="col-5 col-md-3">
            <button className="btn btn-dark text-white w-100" onClick={handleShow1}>
              validation
            </button>
          </div>
          <div className="col-5 col-md-3">
            <button
              className="btn btn-dark text-white w-100"
              onClick={handleShow}
            >
              provision
            </button>
          </div>
        </div>
      </Col>

      <ModalHeaderEn  handleClose={handleClose} clickHandler={clickHandler} userInfo={userInfo} show={show}/>

      <Verficationstep show1={show1} handleClose1={handleClose1} ready2={ready2} userInfo={userInfo} changeHandler={changeHandler} cliclHandler2={cliclHandler2}/>
    </>
  );
};

export default HeaderUser;
