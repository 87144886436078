import React, {useEffect, useState} from 'react'
import { Container, Row } from "react-bootstrap";
import HeaderAdmin from '../../components/HeaderAdmin/HeaderAdmin';
import MainAdmin from '../../components/MainAdmin/MainAdmin';
import Sidebaradmin from "../../components/sidebar/Sidebaradmin";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Clock from '../../components/clock/Clock'
import logo from "../../assets/img/logo.jpg";
import jwt_decode from "jwt-decode";
import axios from "axios";

import './adminpanel.css'
// import axios from "axios";

const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
});
const AdminPanel = ({userinfo}) => {
  const UserAuthorization = localStorage.getItem("token");
  var token = UserAuthorization;
  var decoded = jwt_decode(token);

  var userId = decoded.payload._id;
 
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  const [show1, setShow1] = useState(false);
  const [history, setHistory] = useState([]);
  const [loading,setLoading] = useState(false)
  const [user,setUser] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [refModal,setRefModal] = useState(false)

  var storage = localStorage.getItem('token')
  useEffect(() => {
    api
      .get("api/v1/history")
      .then((res) => {
        setHistory(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
      });

      api.get('api/v1/users', {
        headers: {
          authorization: `Bearer ${storage}`
        }
      }).then((response) =>{
          const allUser = response.data
          const isUser = allUser.filter( (element) => element.isAdmin === false);
          setUser(isUser)
      }).catch((error)=>{
          console.log(error)
      });

      api
      .get("api/v1/history/sum"  )
      .then((resp) => {
        
        setBenefit(resp.data)
       
        // setBenefit(response.data);
      })  
      .catch((error) => {
        console.log(error);
      });
  }, [storage,loading]);

  const eachDeposite = history.filter(
    (element) => (element.comment === 'deposite' || element.comment === 'topup' ) && element.status === "Accept"
  );
  
  
  
  
const totalDeposite = eachDeposite.reduce(function (accumulator, item) {
  return accumulator + item.adminPrice;
}, 0);

// console.log(totalDeposite)

    return (
        <Container fluid className="position-relative bg-df1">
        <Row className=" justify-content-between align-items-center header">
        <div className="navbar" style={{ width: "100%"}}>
          <Link to="#" className="menu-bars">
            <MenuIcon onClick={showSidebar} />
          </Link>
         
          <div className="text-right mt-4 mr-4">
          <Clock />
          <strong className="title2">Infinity Capital</strong>
          <img src={logo} alt="" width="6%" />
          </div>
        </div>
        <div className="col-2">
            <Sidebaradmin sidebar={sidebar} showSidebar={showSidebar} refModal={refModal} setRefModal={setRefModal}/>
          </div>
          <div className="col-10">
          <HeaderAdmin adminId={userId}  user={user} benefit={benefit} userinfo={userinfo} show1={show1} setShow1={setShow1} totalDeposite={totalDeposite} />
          <MainAdmin history={history} user={user} benefit={benefit} setLoading={setLoading}/>
          </div>
          
        </Row>
        {/* <Row className="justify-content-between align-items-center main">
        </Row> */}
      </Container>
        // <div>
        //     <Container fluid className="position-relative">
        //         <Row className=" justify-content-between align-items-center headeradmin">
        //             <HeaderAdmin />
        //         </Row>
        //         <Row className="justify-content-between align-items-center mainadmin">
        //             <MainAdmin />
        //         </Row>
        //     </Container>

        // </div>
    )
}

export default AdminPanel

