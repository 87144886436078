import axios from "axios";
import React, { useState } from "react";
import { useLocation ,useHistory } from "react-router-dom";
import { Form , Button} from "react-bootstrap";
import './verfication.css';
// import logo from "../../assets/img/logo.jpg";
import gg from "../../assets/img/gogle.png";
// import axiosConfig from "../../utils/axiosConfig";
const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
});
var QRCode = require("qrcode");

const Verfication = () => {

  let history = useHistory();
  const location = useLocation();
  const [otpauth, setOtpauth] = useState("");
  const user = location.state.userId2;
  console.log(user)
 
  const [imgUrl, setimgUrl] = useState("");
  const [idd,setIdd]= useState("")
  const [verifyId,setVerifyId] = useState({
    userId : '',
    token : ''
  })

  const [ready,setReady] = useState()

  console.log(ready)
  // useEffect(() => {
  //     const user = location.state.userId
  //     axios.post('http://localhost:3000/api/v1/verfication/register',user).then((res)=>{
  //         setMamad(res.data.docs.temp_secret.otpauth_url)
  //       }).catch((err) =>{
  //         console.log(err)
  //       });
  //  }, [location]);

  //  var amin = QRCode.toDataURL(mamad, function(err, data_url) {
  //         return ( <img src={data_url} alt="" /> )
  //  })

  //  console.log(amin)

  //  QRCode.toDataURL(mamad , function(err, data_url) {
  //     console.log(data_url);

  // Display this data URL to the user in an <img> tag
  // Example:
  //    return var amin = `<img src="' + ${data_url} + '">`
  //   });

  const cliclHandler = () => {
    api
      .post("api/v1/verfication/register", {userId : user})
      .then((res) => {
        setOtpauth(res.data.labelotpauthURL);
        setIdd(res.data.docs._id)
        console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  const cliclHandler2 = (e) => {
    e.preventDefault();
    api
      .post("api/v1/verfication/verify", verifyId)
      .then((res2) => {
        console.log(res2.data.verified)
        if(res2.data.verified === true){
          setReady(true)
          api
        .put("api/v1/users/verfication",  {
          id: user,
          verfication: true
        })
        .then((res3) => {
          console.log(res3);
          history.push('/')
        })
        .catch((err3) => {
          console.log(err3);
        });
        }else{
          setReady(false)
        }
      })
      .catch((err2) => {
        console.log(err2);
      });
      
  };

  // const updateHandler = (ev)=>{
  //   ev.preventDefault();
  //   api
  //       .put("api/v1/users/verfication",  {
  //         id: user,
  //         verfication: true
  //       })
  //       .then((res3) => {
  //         console.log(res3);
  //         history.push('/')
  //       })
  //       .catch((err3) => {
  //         console.log(err3);
  //       });
  // }

  const changeHandler = (e)=>{
    setVerifyId({
      ...verifyId,
      userId : idd,
      token : e.target.value
    })
  }

  if(otpauth){
    var outP = otpauth;
    console.log(otpauth)
  }

  QRCode.toDataURL(outP)
    .then((url) => {
      setimgUrl(url);
    })
    .catch((err) => {
      console.error(err);
    });

  
  return (
    <div className="container pt-3 h-100 d-flex align-items-center justify-content-center ">
      <div className="cardd  pb-3">
        {/* <h2 className="text-center py-4">Infinity Capital</h2>
        <h3 className="text-center">2-Step Verfication</h3> */}
        <img src={gg} alt="" className="img-verficaat" />
        <button className="btn btn-primary d-block mx-auto " onClick={cliclHandler}>
          Set up Authenticator
        </button>
        <img src={imgUrl} alt="" className="d-block mx-auto my-2" width="20%"/>
        <strong className="px-5 ">Enter a verfication code</strong>
        <p className="px-5 my-3">Get a verfication code from the <strong>Google Authenticator</strong> app</p>
        <Form className="px-5">
        <Form.Group controlId="formBasicEmail">
          <Form.Label>Security key</Form.Label>
          <Form.Control type="text" className="h-def" maxLength="6" placeholder="Enter the 6-digit code" onChange={changeHandler} />
        
        </Form.Group>
        <Button variant="warning" className="btn-block py-2" type="submit" onClick={cliclHandler2}>
        Check Code
        </Button>
        {ready === true ? (<p className="text-white bg-success p-2 my-3">Code Accept</p>) : ready === false ? (<p className="text-white bg-danger p-2 my-3">Wrong code</p>) : null}

        <Button variant="success" className="btn-block mt-3 py-3" type="submit"  disabled={ready !== true ? true : false}>
            Done
        </Button>
      </Form>
      </div>
     
    </div>
  );
};

export default Verfication;
