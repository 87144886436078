import React,{useState} from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import './Login.css'
// import bitcoind from '../../assets/img/bit3.jpg'
import FormLogin from '../../components/FormLogin/FormLogin'
// import QRCode from 'qrcode'
const Login = ({setisLogin}) => {

    // const [src,setSrc] = useState('')

    // useEffect(()=>{
    //     const secret = "otpauth://totp/Example:samira@google.com?secret=FJ3WIRZDOVITASRDMFPCYLCPG5YHAI2CIA7HKY32K5TS4USBMJKA&issuer=Example"
    //     QRCode.toDataURL(secret).then((setSrc));
    // },[])
    const [login,setLogin] = useState({
        email : "",
        password : ""
    })
    
    return (
        <Container fluid className="contaLogin position-relative h-100">
            <Row className=" justify-content-start align-items-center ml-md-5 px-3 px-md-0  pl-md-5 h-100">

                <Col xs md="7" lg="5" className=" my-lg-6 p-3 p-md-0 formLogin">
                    <span className="title m-0 pl-md-5 d-block pt-md-4">Infinity Capital</span>
                    <span className="login m-0 pl-md-5">LOGIN</span>
                    <FormLogin setLogin={setLogin} setisLogin={setisLogin} login={login} />
                    {/* <img src={src} alt="" /> */}
                </Col>
                
            </Row>
        </Container>
    )
}

export default Login
