import React from 'react'
import {  Modal, Button , Form } from "react-bootstrap";
import gg from "../../assets/img/gogle.png";
const Verficationstep = ({show1,handleClose1,ready2,userInfo,changeHandler,cliclHandler2,submitHandler}) => {
    return (
        <Modal
        show={show1}
        onHide={handleClose1}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-90w"
        size="xl"
        className="modal2step"
      >
        <Modal.Header closeButton>
          <Modal.Title>VALIDATION</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="container">
      <div className="cardd my-2 pb-3">
        <img src={gg} alt="" className="img-verficaat" />

        <strong className="px-5 ">Enter a verfication code</strong>
        <p className="px-5 my-3">Get a verfication code from the <strong>Google Authenticator</strong> app</p>
        <Form className="px-5">
        <Form.Group controlId="formBasicEmail12">
          <Form.Control type="text" className="h-def" minLength="6" maxLength="6" placeholder="Enter the 6-digit code" onChange={changeHandler}  />
        
        </Form.Group>
        <Button variant="warning" className="btn-block py-3" type="submit" onClick={cliclHandler2}>
        Check Code
        </Button>
        {ready2 === true ? (<p className="text-white bg-success p-2 my-3">Code Accept</p>) : ready2 === false ? (<p className="text-white bg-danger p-2 my-3">Wrong code</p>) : null}

        <Button variant="primary" className="btn-block my-5 py-4 d-none" onClick={handleClose1}   disabled={ready2 === true ? false : true} >
            Done
        </Button>
      </Form>
      </div>
     
    </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose1} disabled={userInfo === true ? false : true}>
            Close
          </Button>
        
        </Modal.Footer>
      </Modal>
    )
}

export default Verficationstep
