import axios from 'axios';
import React, { useState } from 'react'
import { Modal, Button , Form } from 'react-bootstrap';
// import axiosConfig from '../../utils/axiosConfig';
import './deposite.css'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Alert from './Alert'
import { useEffect } from 'react';
import usdttethe from '../../assets/img/usdttethe.png';
import pardakhtOnline from '../../assets/img/pardakhte.png';
// import { Redirect, useHistory } from 'react-router-dom';

const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
  })
const DepositeBtn = ({result,userId,showDeposite,setShowDeposite}) => {
   
    const [alertes,setAlertes] = useState({
        severity : "",
        title : ""
    })
    const [collapse,setCollapse] = useState({
        step1 : true,
        step2 : false
    })
    const [usdtPrice,setUsdtPrice] = useState(0)
    const [wallet,setWallet] = useState('');
    const [open, setOpen] = useState(false);
    const [info,setInfo] = useState({
        price : "",
        trackingCode : "",
        comment : "deposite",
        userId : userId,
    });
    const [toman,setToman] = useState({
        amount : 0,
        finalPrice : 0
    })
    const [validated, setValidated] = useState(false);
    const changeHandler = (e)=>{
        setInfo({
            ...info,
            [e.target.name] : e.target.value,
            
        })
        
    }
   
    const changeHandler2 = (es) => {
        setToman({
            ...toman,
            amount : es.target.value , 
            finalPrice :  (usdtPrice + 120 ) * es.target.value
        })
    }


  
    const clickHandler = (e)=>{
        
        if(info.price.length > 0 && info.trackingCode.length > 0){
            if(info.price < 1000){
                // alert("Your amount is less than $1,000.");
                setAlertes({
                    severity : 'error',
                    title : 'Your amount is less than $1,000.'
                })
                setOpen(true)
            }else{
                api.post('api/v1/history',info).then((res)=>{
                    console.log(res)
                    }).catch((err)=>{
                        console.log(err)
                    })
                    setShowDeposite(false);
                    setAlertes({
                        severity : 'success',
                        title : 'successfully Deposite !'
                    })
                    setOpen(true)
            }
        }else{
            alert('لطفا فیلدهای مورد نظر را پر کنید')
        }
        
    }
  
    const clickHandler2 = () => {
  
        if(toman.amount >= 1000){
            if(toman.finalPrice > 100000000){
                setAlertes({
                    severity : 'error',
                    title : 'مبلغ شما بیشتر از سقف مجاز بانک مرکزی است'
                })
                setOpen(true)
            }else{
                api.post('api/v1/payment',{amount : toman.finalPrice , price : toman.amount,personId : userId }).then((res)=>{
                    // console.log(Object.freeze(res.data.data.url))
                    // history.push(res.data.data.url)
                    window.location = res.data.data.url;
                    }).catch((err)=>{
                        console.log(err)
                    })
                    // setShowDeposite(false);
                    // setAlertes({
                    //     severity : 'success',
                    //     title : 'successfully Deposite !'
                    // })
                    // setOpen(true)
            }
            
        }else{
            setAlertes({
                severity : 'error',
                title : 'مبلغ شما کمتر از ۱۰۰۰ دلار است'
            })
            setOpen(true)
        }
    }

    

    const usdtHandler = () => {
        const axs = api.get('https://api.tetherland.com/currencies').then((res)=>{
            console.log(res.data.data.currencies.USDT.price);
            const priceUsdt = res.data.data.currencies.USDT.price;
            setUsdtPrice(priceUsdt)
            }).catch((err)=>{
                console.log(err)
            })
            // setShowDeposite(false);
            // setOpen(true)
            return axs
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
    
        setValidated(true);
      };

    const getHandler = ()=>{
        setShowDeposite(true)
        api.get('api/v1/walletAdress').then((res2)=>{
            const wal = res2.data[0].walletAdress
            setWallet(wal)
        }).catch((err2)=>{
            console.log(err2)
        })
    }

    const [val,setVal] = useState({
        value: wallet,
        copied: false,
    })

    const handleClose2 = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      useEffect(()=>{
        usdtHandler();
      })


     
    return (
        <div className="row mx-auto mb-4">
            <div className="col-12 px-0">
            <button className="btn btn-success2 text-white w-100 py-4" onClick={getHandler}>Deposit</button>
            {/* <p className="text-danger">Pendding...</p> */}
            </div>
            <div className="col-12">
                <Modal
                    show={showDeposite}
                    onHide={() => setShowDeposite(false)}
                    dialogClassName="modal-Deposite"
                    aria-labelledby="modal-Deposite"
                    size="lg"
                    centered
                >
                    <Modal.Header className='p-0 justify-content-center'>
                        <div className='row w-100'>
                            <div className={ collapse.step1  ? 'bg-white col-6 border-right text-center d-flex align-items-center hdef' : 'bgDef text-white col-6 border-right text-center d-flex align-items-center hdef' } onClick={() => setCollapse({step1 : true})}><img src={usdttethe} alt="usdttethe" width="100%"/></div>
                            <div className={ collapse.step2  ? 'bg-white col-6 border-right text-center d-flex align-items-center hdef' : 'bgDef text-white col-6 border-right text-center d-flex align-items-center hdef' } onClick={() => setCollapse({step2 : true})}> <img src={pardakhtOnline} alt="pardakhtOnline" width="100%" /> </div>
                        </div>
                        {/* <Modal.Title id="modal-Deposite">
                            Are you sure about Deposite?
                        </Modal.Title> */}
                    </Modal.Header>
                    {collapse.step1 ? 
                    <>
                    <Modal.Body>
                        <Form  className="col-12 d-flex flex-column align-items-center px-0" noValidate validated={validated} onSubmit={handleSubmit}>
                            <div className="form-group row w-100">
                                <label className="col-sm-3 col-form-label text-nowrap">Wallet address :</label>
                                <label className="col-sm-6 col-form-label text-nowrap">{wallet}</label>
                                {/* <a className="col-sm-2" href="#samira">copy</a> */}
                                <CopyToClipboard className="col-sm-2 copyClass" text={val.value}
                                onCopy={() => setVal({value :wallet, copied: true})}>
                                <span >Copy</span>
                                </CopyToClipboard>
                                {val.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
                            </div>
                            <Form.Group className="form-group row w-100" >
                                <Form.Label htmlFor="price" className="col-sm-3 col-form-label text-nowrap" name="price" value="">price :</Form.Label>
                                <div className="col-sm-8">
                                    <Form.Control type="text" className="form-control" minLength="3" id="price" name="price"  required  onChange={changeHandler}  placeholder="Minimum amount of money 1000$ " />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid city.
                                    </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <div className="form-group row w-100">
                                <label htmlFor="trackingcode" className="col-sm-3 col-form-label text-nowrap">trackingcode :</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" id="trackingcode" name="trackingCode" onChange={changeHandler} placeholder="trackingcode..." />
                                </div>
                            </div>
                        </Form >


                    </Modal.Body>
                    <Modal.Footer  className=" justify-content-between">
                        <button className="btn btn-success text-white col-7" disabled={info.price.length > 0 && info.trackingCode.length > 0 ? false : true} onClick={clickHandler}>Deposite</button>
                        <Button onClick={() => setShowDeposite(false)} className=" btn-danger">Close</Button>
                    </Modal.Footer>
                    </>
                    :
                    <>
                    <Modal.Body className='dirRight row'>
                    <Form  className="col-12 col-md-6 d-flex flex-column align-items-center px-0" noValidate validated={validated} onSubmit={handleSubmit}>
                        <div className="form-group row w-100">
                            <label className="col-2 col-form-label text-nowrap">قیمت :</label>
                            <label className="col-7 pr-4 col-form-label text-nowrap text-warning">{usdtPrice + 120} تومان</label>
                           
                        </div>
                        <Form.Group className="form-group row w-100" >
                            <Form.Label htmlFor="price" className="col-2 col-form-label text-nowrap" name="price"> تعداد :</Form.Label>
                            <div className="col-7 ">
                                <Form.Control type="number" className="form-control"  id="amount" name="amount"  required  onChange={changeHandler2}  placeholder="حداقل پرداخت 1000 دلار" />
                                <Form.Control.Feedback type="invalid">
                                    Please provide a valid city.
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <div className="form-group row w-100">
                            <label htmlFor="trackingcode" className="col-3 col-form-label text-nowrap">مبلغ پرداختی :</label>
                            <label className="col-7 pr-4 col-form-label text-nowrap">
                                <span className='boxdef'>{toman.finalPrice}</span> 
                                تومان
                            </label>
                        </div>
                    </Form >

                        <div className='col-12 col-md-4 textDrf'>
                        بر اساس قوانین بانک مرکزی سقف پرداخت اینترنتی ۱۰۰ میلیون تومان و حداکثر از ۲ کارت ۲۰۰ میلیون تومان در روز میباشد.
                        </div>
                </Modal.Body>
                 <Modal.Footer  className=" justify-content-between">
                 <button className="btn btn-success pardakht text-white col-7" disabled={toman.amount.length > 0  ? false : true} onClick={clickHandler2}>پرداخت</button>
                 <Button onClick={() => setShowDeposite(false)} className=" btn-danger">Close</Button>
             </Modal.Footer>
             </>
                    }
                   
                </Modal>
                <Alert open={open} alertes={alertes} handleClose={handleClose2}  />

            </div>
        </div>

    )
}

export default DepositeBtn
