import axios from 'axios';
import React, {  useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
import moment from 'moment';

const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
  });

const WithdrowBtn = ({avalble,showWithdrow,setShowWithdrow,setShow1,topUpMoney,userId,lastArrWithdrow}) => {
    const [walletAddres,setWalletAddres] = useState("")
    const registerHandler = (e)=>{
        setWalletAddres({
            ...walletAddres,
            [e.target.name] : e.target.value
        })
    }   
    // console.log(lastArrWithdrow)
     if( typeof lastArrWithdrow == 'undefined' ){
        var isafter = true
     }else{

        var newDate = moment(new Date());
        var itemDate = moment(lastArrWithdrow.createdAt);
        var difrenceDay =  newDate.diff(itemDate, 'days');
        if(difrenceDay > 29) {
            isafter = true;
        }else{
            // isafter = moment(new Date()).isAfter(x);\
            isafter = false;
        }
         
        //  console.log(difrenceDay)
     }
     

  
    // const [info] = useState({
    //     price :   20  ,
    //     comment : "withdrow",
    //     userId : userId,
    //     walletNumber : walletAddres
    // });
    const topupHandler = (e)=>{
        if(topUpMoney > 0 ){
            api.post('api/v1/history',{ price : topUpMoney , comment : "withdrow", userId : userId, walletNumber : walletAddres.walletNumber}).then((res)=>{
                // console.log(res.data.messege)
                alert("Withdrawal request registered successfully")
                }).catch((err)=>{
                    console.log(err)
                })
                setShowWithdrow(false)
                // setShowDeposite(false);
                // setOpen(true)
        }else{
            alert('موجودی ناکافی')
        }
        
    }
   
    


    return (
        <div className="row mx-auto my-3">
            <div className="col-12 px-0">
                <button className="btn btn-success3 text-white w-100 py-4 " onClick={() => setShowWithdrow(true)}>Withdrow</button>
            </div>
            <div className="col-12">
                <Modal
                    show={showWithdrow}
                    onHide={() => setShowWithdrow(false)}
                    dialogClassName="modal-Withdrow"
                    aria-labelledby="modal-Withdrow"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="modal-Withdrow">
                            Are you sure about withdrow?
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="col-12 d-flex flex-column align-items-center px-0">
                            <div className="form-group row">
                                <label for="walletAddress" className="col-sm-4 col-form-label text-nowrap">Wallet address :</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" name='walletNumber' id="walletAddress" onChange={registerHandler} placeholder="Wallet address..." />
                                </div>
                            </div>
                            <p className="font-weight-bold w-100 text-center">Amount of interest : {topUpMoney} $</p>
                            {/* <Button className="btn btn-warning text-white col-12 col-md-6" onClick={()=> setShow1(true)}>Validation</Button> */}
                        </form>
                        
                        
                    </Modal.Body>
                    <Modal.Footer closeButton className=" justify-content-between">
                        <Button onClick={topupHandler} className="btn btn-danger text-white col-7" disabled = {topUpMoney === 0 ? true : false || isafter === true ? false : true } >Withdraw</Button>
                        <Button onClick={() => setShowWithdrow(false)} className=" btn-secondary">Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>
    )
}

export default WithdrowBtn
