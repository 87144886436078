
import React from 'react'
import { Col } from 'react-bootstrap';
import './MainUser.css'
import TableUser from '../TableUser/TableUser'
import TopupBtn from '../TopupBtn/TopupBtn';
import WithdrowBtn from '../WithdrowBtn/WithdrowBtn';
import DepositeBtn from '../DepositeBtn/DepositeBtn';
import moment from 'moment'
import Carousel from '../carousel/Carousel';
import Carouselfiat from '../carousel/Carouselfiat'
import Refferal from '../Referral/Refferal';

const MainUser = ({totalPrice,result,eachBenefit,userId,eachBenefit2,Allresult,userAllTopUp,userTopUp,setShow1,showTopup,setShowTopup,resultLast,showDeposite,setShowDeposite,lastArrWithdrow,showWithdrow,setShowWithdrow,setShowRef,showRef}) => {
    const topupDate = userTopUp.find((element) => element.createdAt );
    const topupDatee =   topupDate ? topupDate.verficationDate : new Date();
    var newDate = moment(new Date());
     var createDate = resultLast ? resultLast.verficationDate ? resultLast.verficationDate : resultLast.updatedAt  : new Date();
     var firstDeposit = moment(createDate);
     var sumDay = newDate.diff(firstDeposit, 'days');
    //  var difrenceDay =  newDate.diff(firstDeposit, 'days');
    var IntDatee = parseInt(sumDay / 30);
    var exDay = (IntDatee * 30);
    var newIntDate = sumDay - exDay;
    
    let totalBenefit = eachBenefit2.reduce(function (accumulator, item) {
        return accumulator + item.adminPrice
    }, 0);

    var cm = totalBenefit * 0.06
            var cr = cm / 30
            console.log("asb",cr)

            // var lsf = Allresult.filter( e => e.comment === "withdrow")
       
            // let lastAllresult = lsf[lsf.length - 1];
            // var ms = lastAllresult.updatedAt;

    var profitDay = newIntDate * cr;
    // var roundProfitDay = Math.round(profitDay * 100) / 100;
  
     var allProfit = sumDay * cr;

    //  var tpup = sumDay * ms;
     
     var roundAllProfit = Math.round(allProfit * 100) / 100;
    //  var roundAllTopup = Math.round(allProfit * 100) / 100;
     console.log(profitDay)
     var topUpMoney =  roundAllProfit 
     console.log("tp",topUpMoney)
    var newTopupDate = moment(topupDatee);
    var difrenceDayTopup = newDate.diff(newTopupDate, 'days');

        var IntDateTopup = parseInt(difrenceDayTopup / 30);
        var exDayTopup = (IntDateTopup * 30);
        var avalble = exDayTopup * cr;

        console.log("ss",showDeposite)
        var Dep = Allresult.filter(e => e.comment === "deposite" && e.status === "Pending")
        console.log('fly',Dep.length)

    return (
        <Col xs className="mt-lg-5 p-4 mainUser">
            <div className="row justify-content-between">
                <TableUser tableopt={totalPrice} eachBenefit2={eachBenefit2} Allresult={Allresult} resultLast={resultLast} result={result} eachBenefit={eachBenefit} userTopUp={userTopUp} userAllTopUp={userAllTopUp} />
                
            </div>
            <div className="row justify-content-between flex-column-reverse flex-column flex-md-row">
                <div className="col-12 col-md-4 my-md-5">
                {totalPrice === 0 ? null : <Carousel /> }
                    <div className="row mx-auto">
                        <button className="btn btn-success3 text-white col-12 mt-4 py-4">Withdraw all money</button>
                        
                    </div>
                </div>

                <div className="col-12 col-md-4 my-5">
                {totalPrice === 0 ? null : <Carouselfiat/> }
                </div>
                <div className="col-12 col-md-4 mt-5 my-md-5">
                    <TopupBtn totalPrice={totalPrice} topUpMoney={topUpMoney} result={result} resultLast={resultLast} userId={userId} avalble={avalble} userAllTopUp={userAllTopUp} roundAllProfit={roundAllProfit} showTopup={showTopup} setShowTopup={setShowTopup} userTopUp={userTopUp} />
                    { Dep.length > 0 ? 
                    <button class="btn btn-success2 text-white w-100 py-4">Deposit</button>
                    :
                    <DepositeBtn result={result} userId={userId} showDeposite={showDeposite} setShowDeposite={setShowDeposite}/>


                    }
                    <WithdrowBtn avalble={avalble} lastArrWithdrow={lastArrWithdrow} topUpMoney={topUpMoney} showWithdrow={showWithdrow} setShowWithdrow={setShowWithdrow} userId={userId} setShow1={setShow1}/>
                    <Refferal setShowRef={setShowRef} showRef={showRef} userId={userId}/>
                </div>
            </div>
        </Col>
    )
}

export default MainUser

