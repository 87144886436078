import React from 'react'
import Clock1 from 'react-live-clock';
// import moment from 'moment';
import './clock.css'
const Clock = () => {
    return (
        <>
        <div className="d-none d-md-block">
            <Clock1 
            format={'dddd D, MMMM M , YYYY, h:mm:ss A '}
            className='text-infoo'
            ticking={true}
            
             />
             </div>
            </>
    
    )
}

export default Clock
