import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import Alert from './Alert'
const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
});


const UpdateUser = () => {
    const [register,setRegister] = useState({
        password : "",
    })
    const [showNewUser, setshowNewUser] = useState(false);
    const [eachuser, setEachuser] = useState([]);
    const [open, setOpen] = useState(false);

    const UserAuthorization = localStorage.getItem("token");
  
    const registerHandler = (e)=>{
      setRegister({
          ...register,
          [e.target.name] : e.target.value
      })
  };

  const handleClose2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

    useEffect(() => {
      const headers = { Authorization: `Bearer ${UserAuthorization}` };
      api
        .get("api/v1/users", { headers })
        .then((resp) => {
          setEachuser(resp.data);
        })
        .catch((err2) => {
          console.log(err2);
        });
    }, [UserAuthorization]);

    const rebels = eachuser.filter((pilot) => pilot.isAdmin === false);

    // console.log(register)
    const submitHandler = (contactId) => {
        console.log(register.password.length)
       if(register.password.length > 0){
        api
        .post("api/v1/users/updatePassword", { id: contactId , password : register.password})
        .then((res4) => {
          setOpen(true);
          setshowNewUser(false)
        })
        .catch((err3) => {
          console.log(err3);
        });
       }
      
    };

    
  

        return (
          <div className="row mx-auto my-3">
            <div className="col-12 px-md-0">
              <button
                className="btn btn-primary text-white"
                onClick={() => setshowNewUser(true)}
              >
                Password User
              </button>
            </div>

            <Modal
          show={showNewUser}
          onHide={() => setshowNewUser(false)}
          dialogClassName="modal-showNewUser"
          aria-labelledby="modal-showNewUser"
          centered
          size="lg"
        >
          <Modal.Title id="modal-showNewUser" className="p-3">
            Are you sure about Delete User?
          </Modal.Title>
          <Modal.Body>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Row</th>
                  <th>Email</th>
                  <th>New User</th>
                  <th>Action</th>
                  
                </tr>
              </thead>
              <tbody>
                {rebels.map((e, i) => (
                  <tr key={i}>
                    <td>{i}</td>
                    <td>{e.email}</td>
                    <td>
                    <input type="text" className="form-control" id="PasswordUser" name="password" placeholder="New Password..." onChange={registerHandler}/>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => submitHandler(e._id)}
                      >
                        Update
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className=" justify-content-end ">
            <Button
              onClick={() => setshowNewUser(false)}
              className=" btn-danger"
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Alert open={open}  handleClose={handleClose2}  />


            
            {/* <Modal
                    show={showNewUser1}
                    onHide={() => setshowNewUser1(false)}
                    dialogClassName="modal-showNewUser"
                    aria-labelledby="modal-showNewUser"
                    centered
                >
                    <Modal.Title id="modal-showNewUser" className="p-3">
                        Are you sure about Update Password User?
                    </Modal.Title>
                    <Modal.Body>

                
                        <div className="form-group row">
                            <label className="col-sm-4 col-form-label text-nowrap">New Password :</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="PasswordUser" name="password" placeholder="New Password..." onChange={registerHandler}/>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className=" justify-content-between">
                        <button className="btn btn-primary text-white col-7" onClick={submitHandler}>Update Password</button>
                        <Button onClick={() => setshowNewUser(false)} className=" btn-secondary">Close</Button>
                    </Modal.Footer>
                </Modal> */}
            </div>
        
        );
      
    
}

export default UpdateUser
