import React from 'react'
import '../MainUser/MainUser.css'
import moment from 'moment'
import './tableuser.css'
import { Table } from "react-bootstrap";

const TableUser = ({ tableopt, result, eachBenefit,eachBenefit2 ,userTopUp, userAllTopUp,resultLast, Allresult }) => {

    // const topupDate = userTopUp.find((element) => element.createdAt );
    // const topupDatee = topupDate ? topupDate.createdAt : new Date();
    // console.log(eachBenefit2)
    if (result && result.status === "Accept" && eachBenefit) {
        // var createDate = result.createdAt;
        var createDate = resultLast ? resultLast.verficationDate ? resultLast.verficationDate : resultLast.updatedAt  : new Date() ;
        var firstDeposit = moment(createDate);
        // console.log(createDate)
        // console.log(Allresult.filter((e) => e.verficationDate))
        var lsf = Allresult.filter( e => e.comment === "withdrow")
    //    console.log(createDate)
        let lastAllresult = lsf[lsf.length - 1];
        var ms = lastAllresult?.updatedAt;
      
        var nsd = result.userId.endDate;
       
        
        let totalBenefit = eachBenefit2.reduce(function (accumulator, item) {
            return accumulator + item.adminPrice;
        }, 0);
        // console.log('dd',totalBenefit)
        var cm = totalBenefit * 0.06
            var cr = cm / 30
            // console.log("asb",cr)

        // var newBegin = moment(nsd);
        var newDate = moment(new Date());
        //  var newTopupDate = moment(topupDatee);  

        /* profit day */
        var difrenceDay =  newDate.diff(firstDeposit, 'days');
        // var  = difrenceDayy - 1
        // console.log(difrenceDay)
        //  var difrenceDayTopup = 45 //newDate.diff(newTopupDate, 'days');
        /* profit all */
        // var sumDay = newDate.diff(firstDeposit, 'days');
        var sumDay2 =  newDate.diff(firstDeposit, 'days');
        
        var IntDate = parseInt(difrenceDay / 30);
        // var IntDateTopup = parseInt(difrenceDayTopup / 30);
        
        var exDay = (IntDate * 30);
        //  var exDayTopup = (IntDateTopup * 30);
        // var avalble = exDayTopup * totalBenefit;
        // console.log(avalble)
        var newIntDate2 = difrenceDay - exDay;
        // var newIntDate2 = newIntDate + 1;

        var profitDay = newIntDate2 * cr;
        var roundProfitDay = Math.round(profitDay * 100) / 100;
        var allProfit = sumDay2 * cr;
        // const amin = userAllTopUp.forEach(element => element.adminPrice);
        // var finalAllProfit = allProfit - userAllTopUp[0].adminPrice
        // console.log(userAllTopUp[0].adminPrice)

        console.log(resultLast)
        
        // console.log("ss",newIntDate2)
        var roundAllProfit = Math.round(allProfit * 100) / 100;
       
        var sweeterArray = userTopUp.map((sweetItem) => {
            return sweetItem;
          });
          

        const allWithdrowAndTopup = sweeterArray.filter(
            (element) =>
               element.comment === "topup" || element.comment === "withdrow"
          );
     
        var TotalProfitsReceived = allWithdrowAndTopup.reduce(
            (n, { adminPrice }) => n + adminPrice,
            0
           );
           var TotalProfitsReceivedRound = Math.round(TotalProfitsReceived * 100) / 100;
            
    } else {
        return null
    }


    return (
        <div className="col-12">
            <Table className="table table-dark" responsive>
                <thead>
                    <tr>
                        <th scope="col">Amount of interest</th>
                        <th scope="col">Start the contract</th>
                        <th scope="col">End of contract</th>
                        <th scope="col">All capital</th>
                        <th scope="col">All profits</th>
                        <th scope="col">Referral</th>
                        <th scope="col">Total profits received</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{result && result.status === "Accept" ? roundProfitDay : "0"} $</th>
                        <td>{moment(nsd).format('DD/MM/YYYY')}</td>
                        <td>{moment(nsd).add(364, 'days').format('DD/MM/YYYY')}</td>
                        <td>{tableopt} $</td>
                        <td>{result && result.status === "Accept" ? roundAllProfit : "0"} $</td>
                        <td>0 $</td>
                        <td>{TotalProfitsReceivedRound}</td>
                    </tr>
                </tbody>
            </Table>

        </div>

    )
}

export default TableUser
