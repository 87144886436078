import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import HeaderUser from "../../components/HeaderUser/HeaderUser";
import MainUser from "../../components/MainUser/MainUser";
import jwt_decode from "jwt-decode";
import Sidebaruser from "../../components/sidebar/Sidebaruser";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.jpg";
import './panel.css'
import Clock from '../../components/clock/Clock';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
// import axiosConfig from "../../utils/axiosConfig";

const api = axios.create({
  baseURL : `https://api.myinfinitycapital.com/`
});
const Panel = ({userInfo}) => {
  const [user, setUser] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [show1, setShow1] = useState(false);
  const [showTopup, setShowTopup] = useState(false);
  const [showDeposite, setShowDeposite] = useState(false);
  const [showWithdrow, setShowWithdrow] = useState(false);
  const [showRef,setShowRef] = useState(false)
  console.log(userInfo)
  const UserAuthorization = localStorage.getItem("token");
  var token = UserAuthorization;
  var decoded = jwt_decode(token);
 
  var userId = decoded.payload._id;

  useEffect(() => {
    // if(userInfo === false) {
    //   window.onload()
    // }
    api
      .post("api/v1/history/userid", { userId })
      .then((response) => {
        console.log(response.data)
        setUser(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

      api
      .get("api/v1/history/sum")
      .then((resp) => {
        setBenefit(resp.data);

        // setBenefit(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userId]);

 
    
  const result = user.find((element) => element.userId._id === userId);

  const Allresult = user.filter((element) => element.userId._id === userId);
  //  const flAllresult = Allresult.filter((e) => e.verficationDate)
  const resultLast =  Allresult[Allresult.length - 1];
  // console.log("lastt",resultLast)

  const eachBenefit = benefit.filter(
    (element) => element.userId === userId && element.status === "Accept"
  );
  const eachBenefit1 = user.filter(
    (element) => element.userId._id === userId && element.status === "Accept"
  );

  const eachBenefit2 = eachBenefit1.filter(
    (element) => element.comment === "deposite" || element.comment === "topup"
  );
  console.log(eachBenefit2)
  // const itemPrice = user.filter((element) => element.status === "Accept");
  const itemPrice = user.filter((element) => element.comment === "deposite" || element.comment === "topup");
  console.log(itemPrice)
  let totalPrice = itemPrice.reduce(function (accumulator, item) {
    
    return accumulator + item.adminPrice;
  }, 0);
  const userAllTopUp = user.filter((element) => element.comment === "topup" );
  const userAllWithdrow = user.filter((element) => element.comment === "withdrow" || element.comment === "deposite");
  
  var lastArrWithdrow = userAllWithdrow[userAllWithdrow.length - 1];
  
  const userTopUp1 = user.filter((element) => element.comment === "topup" || element.comment === "withdrow" || element.comment === "deposite" );

  const userTopUp = userTopUp1.filter((element2) => element2.status === "Accept" );


  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  
  const closeHandler = ()=>{
    localStorage.clear();
    window.location.reload()
}
  return (
    <Container fluid className="position-relative bg-df1 ">
      <Row className=" justify-content-between align-items-center header">
      <div className="navbar" style={{ width: "100%"}}>
        <Link to="#" className="menu-bars d-none d-md-block">
          <MenuIcon onClick={showSidebar} />
        </Link>

        <Link to="#" className="menu-bars d-block d-md-none">
          <PowerSettingsNewIcon style={{ fontSize: 35, marginTop : 35 }} onClick={closeHandler} />
        </Link>
       
        <div className="text-right mt-4 mr-4">
        <Clock  />
        <strong className="title2">Infinity Capital</strong>
        <img src={logo} alt="" width="6%" />
        </div>
      </div>
      <div className="col-2 d-none d-md-block">

          <Sidebaruser sidebar={sidebar} show1={show1} setShow1={setShow1} showSidebar={showSidebar}
            setShowTopup={setShowTopup}
            setShowDeposite={setShowDeposite}
            setShowWithdrow={setShowWithdrow}
            setShowRef={setShowRef}
            showRef={showRef}
            />
        </div>
        <div className="col-md-10">
          <HeaderUser show1={show1} setShow1={setShow1} userInfo={userInfo} />
          <MainUser
            totalPrice={totalPrice}
            result={result}
            eachBenefit={eachBenefit}
            eachBenefit2={eachBenefit2}
            userId={userId}
            userTopUp={userTopUp}
            showTopup={showTopup}
            setShowTopup={setShowTopup}
            showDeposite={showDeposite}
            setShowDeposite={setShowDeposite}
            showWithdrow={showWithdrow}
            setShowWithdrow={setShowWithdrow}
            setShow1={setShow1}
            userAllTopUp={userAllTopUp}
            resultLast = {resultLast}
            Allresult={Allresult}
            lastArrWithdrow = {lastArrWithdrow}
            setShowRef={setShowRef}
            showRef={showRef}
          />
        </div>
        
      </Row>
      {/* <Row className="justify-content-between align-items-center main">
      </Row> */}
    </Container>
  );
};

export default Panel;
