import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
// import { useHistory } from 'react-router';
import './exit.css'
const ExitBtn = () => {
    
    // let history = useHistory()
    const [showExit, setShowExit] = useState(false);

    const closeHandler = ()=>{
        localStorage.clear();
        window.location.reload()
    }
    return (
        <div className="row mx-auto my-3">
            <div className="col-12 px-md-0">
            <button className="btn btn-def text-white " onClick={() => setShowExit(true)}>Exit</button>
            </div>
            <div className="col-12">
                <Modal
                    show={showExit}
                    onHide={() => setShowExit(false)}
                    dialogClassName="modal-showExit"
                    aria-labelledby="modal-showExit"
                    centered
                >
                    <Modal.Body id="modal-showExit">
                    Are you sure about Exit?
                    </Modal.Body>

                    <Modal.Footer  className=" justify-content-between">
                        <button className="btn btn-danger text-white col-7" onClick={closeHandler}>Exit</button>
                        <Button onClick={() => setShowExit(false)} className=" btn-secondary">Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>

    )
}

export default ExitBtn
