import axios from 'axios';
import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap';
// import axiosConfig from '../../utils/axiosConfig';
const api = axios.create({
    baseURL : `https://api.myinfinitycapital.com/`
  });
const NewUserBtn = () => {
    const [showNewUser, setshowNewUser] = useState(false);
    const [register,setRegister] = useState({
        email : "",
        password : "",
    })
    const registerHandler = (e)=>{
        setRegister({
            ...register,
            [e.target.name] : e.target.value
        })
    }

    const submitHandler = ()=>{
        api.post('api/v1/users/register',register).then((response)=>{
            console.log(response)
            setshowNewUser(false)
        }).catch((err)=>{
            console.log(err)
        })
    }

    // console.log(register)
    return (
        <div className="row mx-auto my-3">
            <div className="col-12 px-md-0">
                <button className="btn btn-primary text-white" onClick={() => setshowNewUser(true)}>New User</button>
            </div>
            <div className="col-12">
                <Modal
                    show={showNewUser}
                    onHide={() => setshowNewUser(false)}
                    dialogClassName="modal-showNewUser"
                    aria-labelledby="modal-showNewUser"
                    centered
                >
                    <Modal.Title id="modal-showNewUser" className="p-3">
                        Are you sure about New User?
                    </Modal.Title>
                    <Modal.Body>

                        <div className="form-group row">
                            <label for="EmailUserAddress" className="col-sm-4 col-form-label text-nowrap">Email :</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="EmailUserAddress" name="email" placeholder="Email..." onChange={registerHandler} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label for="PasswordUser~" className="col-sm-4 col-form-label text-nowrap">Password :</label>
                            <div className="col-sm-8">
                                <input type="text" className="form-control" id="PasswordUser" name="password" placeholder="Password..." onChange={registerHandler}/>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer className=" justify-content-between">
                        <button className="btn btn-primary text-white col-7" onClick={submitHandler}>New User</button>
                        <Button onClick={() => setshowNewUser(false)} className=" btn-secondary">Close</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        </div>

    )
}

export default NewUserBtn
