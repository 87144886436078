import React, { useEffect } from 'react'
import Verficationstep from '../../components/2step/Verficationstep'
import jwt_decode from "jwt-decode";
import Panel from '../Panel/Panel';
import AdminPanel from '../AdminPanel/AdminPanel';


const Verficationmain = ({show1,handleClose1,ready2,userInfo,changeHandler,cliclHandler2,handleShow1}) => {
    const UserAuthorization = localStorage.getItem("token");
    var tokenn = UserAuthorization;
    var decodedd = jwt_decode(tokenn);
    var isAdmin = decodedd.payload.isAdmin;
    // console.log(ready2)
    useEffect(()=>{
        handleShow1()
    },[handleShow1])
    // console.log(userInfo)
    return (
        <div>
           { ready2 === undefined || ready2 === false ? <Verficationstep show1={show1} handleClose1={handleClose1} ready2={ready2} userInfo={userInfo} changeHandler={changeHandler} cliclHandler2={cliclHandler2} /> : isAdmin === true ?  <AdminPanel userinfo={userInfo}/> : <Panel userInfo={userInfo}/>} 
        </div>
    )
}

export default Verficationmain
