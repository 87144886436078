const Validation = (login) => {

    let error = {};
    
    if (!login.email) {
        error.email = "empty filed"
    }else if(!/\S+@\S+\./.test(login.email)){
        error.email = "Email in Invalid"
    }
    if (!login.password) {
        error.password = "empty filed"
    }else if(login.password.length < 5){
        error.password = "Password most be more than five characters"
    }

    return error;
}

export default Validation
