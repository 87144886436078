import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
const MyReCaptcha = ({ setExpired }) => {
  // const SITE_KEY = "6LcawS8dAAAAAOz99SAdtImyzjf_GSQr4JfHvgsK";
  const SITE_KEY = "6Lelsi8dAAAAAGG-JBv-q5tNkRlgO6bZKd-Z8hrS";

  
  const handleChange = (value) => {
    if (value) {
      setExpired(true);
    } else {
      setExpired(false);
    }
  };

  

  return (
    <div>
      <ReCAPTCHA
        style={{
          display: "inline-block",
          margin: "20px 0",
        }}
        theme="dark"
        sitekey={SITE_KEY}
        onChange={handleChange}
      />{" "}
    </div>
  );
};

export default MyReCaptcha;
