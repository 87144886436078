import React  from "react";
import ConfirmBtn from "../ConfirmBtn/ConfirmBtn";
// import Editbtn from "../Editbtn/Editbtn";
import "./TableAdmin.css";
import { Accordion, Card, Button, Table } from "react-bootstrap";
import moment from "moment";

const TableAdmin = ({ user, history, benefit , setLoading}) => {

  
  var sweeterArray = history.map((sweetItem) => {
    return sweetItem;
  });
  
  return (
    <div className="col-12">
      <Table  className="table table-dark tbladmin" responsive>
        <thead>
          <tr>
            <th scope="col">User Email</th>
            <th scope="col">Details</th>
            <th scope="col">Contract Start date</th>
            <th scope="col">Contract End date</th>
            <th scope="col">Total capital</th>
            <th scope="col">Total profit</th>
            <th scope="col">Daily profit</th>
            <th scope="col">Interest rates</th>
            <th scope="col">Total profits received</th>

            
            {/* <th scope="col">وضعیت</th> */}
          </tr>
        </thead>
        <tbody>
          {user.map((eachUser, index) => {
            
            
            // const filterHitory1 = sweeterArray.filter(
            //   (element) =>
            //     element.userId._id === eachUser._id 
            // );
           
            // const eachRef = filterHitory1.filter(
            //   (element) => element.comment === 'referral' && element.status === "Accept"
            // );
            const filterHitory2 = sweeterArray.filter(
              (element) =>
                element.userId._id === eachUser._id && element.status === "Accept"
            );
            const filterHistory3 = filterHitory2.filter((element) => element.comment === "deposite" || element.comment === "topup");

            // const filterHistoryLast = filterHitory2.filter((element) => element.comment === "deposite" || element.comment === "topup" || element.comment === "withdrow");
            const filterHistoryLast = filterHitory2.filter((element) => element.comment === "deposite" || element.comment === "topup");


            const allWithdrowAndTopup = sweeterArray.filter(
              (element) =>
                element.userId._id === eachUser._id 
            );
            const allWithdrowAndTopup2 = allWithdrowAndTopup.filter(
              (element) =>
              element.comment === "withdrow" || element.comment === "topup" || element.comment === 'referral'
            );

            // console.log("asw",allWithdrowAndTopup2)
           

            const PendingStatus = sweeterArray.filter(
              (element) =>
                
              element.userId._id === eachUser._id && element.status === "Pending" 
            );

            const lastItem = filterHistoryLast[filterHistoryLast.length - 1]

            const result = filterHistoryLast[0];
            if (result === undefined) {
              // return null;
              var startDate = new Date();
            } else {
              var startDate = lastItem.verficationDate;
              
            }
            // console.log("res",lastItem)

            // element.comment === "deposite" || element.comment === "topup"
            // const eachBenefit = benefit.filter(
            //   (element) =>
            //     element.userId === eachUser._id && element.status === "Accept"
            // );

            const eachBenefit1 = history.filter(
              (element) =>
                element.userId._id === eachUser._id && element.status === "Accept"
            );

            const eachBenefit2 = eachBenefit1.filter(
              (element) =>
              element.comment === "deposite" || element.comment === "topup"
            );

            let totalBenefit1 = eachBenefit2.reduce(function (accumulator, item) {
              return accumulator + item.adminPrice;
            }, 0);

            

            var cm = totalBenefit1 * 0.06
            var cr = cm / 30
           

            // let totalBenefit = eachBenefit.reduce(function (accumulator, item) {
            //   return accumulator + item.profit.profitDay;
            // }, 0);

            const allPrice = filterHistory3.reduce(
              (n, { adminPrice }) => n + adminPrice,
              0
             );
             

             const TotalProfitsReceived = allWithdrowAndTopup2.reduce(
              (n, { adminPrice }) => n + adminPrice ,
              0
             );
            const filterUserr = history.filter(
              (element) => element.userId._id === eachUser._id 
            );
            // console.log('user',filterUserr.length)

            // var flpt = filterUserr.length > 0
            // console.log('tt',flpt)
            // console.log('rr',history)
            const filtereachUserr = history.filter(
              (element) => element.userId._id === eachUser._id && element.status === "Accept" && (element.comment === "deposite" || element.comment === "topup" || element.comment === "withdrow")
            );

           
            
            // const totalDeposite2 = sweeterArray.reduce(function (accumulator, item) {
            //   return accumulator + item.verficationDate;
            // }, 0);

              

            var nsd = eachUser.endDate;
            
            // var verfication = filterHitory1
            // console.log(sweeterArray)
            var newBegin = moment(nsd);
            var newDate = moment(new Date());
        
            var difrenceDay = newDate.diff(newBegin, "days");
            var sumDay = newDate.diff(startDate, "days");
            var IntDate = parseInt(difrenceDay / 30);
            var newIntDate = difrenceDay - IntDate * 30;
            var profitDay = newIntDate * cr;
            
            // console.log('sag',profitDay)
            var roundProfitDay = Math.round(profitDay * 100) / 100;
            var allProfit = sumDay * cr;
            // console.log('cr',cr)
            var roundAllProfit = Math.round(allProfit * 100) / 100;
            
              
            return (
             
              
              <tr key={index}>
                <td >
                  {PendingStatus.length > 0 ? ( <span className="alertNewMsg">!</span>) : null}
                  {eachUser.email}</td>
                <td>
                  <Accordion>
                    <Card>
                      <Card.Header>
                        <Accordion.Toggle className="btn-def2" as={Button} eventKey="0">
                          View
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey="0">
                        <Table className="table table-dark tbladmin" responsive>
                          <thead>
                            <tr>
                              <th scope="col">row</th>
                              <th scope="col">Money</th>
                              <th scope="col">Deposit date</th>
                              <th scope="col">approval date</th>
                              <th scope="col">Type</th>
                              <th scope="col">Interest rates</th>
                              <th scope="col">Condition</th>
                              <th scope="col">WalletNumber</th>
                              <th scope="col">Edit</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filterUserr.map((eachHistory, index) => (
                              <tr key={index}>
                                <td>{index}</td>
                                <td>{eachHistory.adminPrice || eachHistory.referralBenfit} $</td>
                                <td>
                                  {moment(eachHistory.createdAt).format("DD/MM/YYYY")}
                                </td>

                                <td>
                                  {filtereachUserr[filtereachUserr.length - 1] && filtereachUserr[filtereachUserr.length - 1].status === "Accept" ? moment(eachHistory.verficationDate).format("DD/MM/YYYY") : "No Date"}
                                </td>
                                <td>
                                  {eachHistory.comment === "deposite"
                                    ? "deposit"
                                    : eachHistory.comment === "topup"
                                    ? "topup"
                                    : eachHistory.comment === "referral" ? "referral" : "withdrow"}
                                </td>

                                <td>
                                  {eachHistory.interestProfit}
                                  {/* <Editbtn /> */}
                                </td>
                                <td>
                                  {eachHistory.status === "Accept" ? (
                                    <p className="text-success">Accept</p>
                                  ) : eachHistory.status === "Pending" ? (
                                    <p className="text-warning">Pending</p>
                                  ) : (
                                    <p className="text-danger">Reject</p>
                                  )}
                                </td>
                                <td>{eachHistory.walletNumber}</td>
                                <td className="d-block">
                                  <ConfirmBtn eachHistory={eachHistory} setLoading={setLoading}/>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </td>
                <td>{filtereachUserr[filtereachUserr.length - 1] && filtereachUserr[filtereachUserr.length - 1].status === "Accept" ? moment(eachUser.endDate).format("DD/MM/YYYY") : "No Date"}</td>
                <td>{filtereachUserr[filtereachUserr.length - 1] && filtereachUserr[filtereachUserr.length - 1].status === "Accept" ? moment(filtereachUserr[filtereachUserr.length - 1].verficationDate).add(364, 'days').format("DD/MM/YYYY") : "No Date"}</td>
                <td>{filterHitory2  ? allPrice.toFixed(2) : "0"}</td>
                <td>{roundAllProfit} $</td>
                <td>{roundProfitDay} $</td>
                <td>
                 {filterUserr.length > 0 ? filterUserr[0].interestProfit : 0}
                  {/* <Editbtn /> */}
                </td>
                <td>{TotalProfitsReceived.toFixed(2)}</td>
                {/* <td>
                            1
                          </td> */}
              </tr>

              
            );
          
          })}

          
        </tbody>
      </Table >

    </div>
  );
};

export default TableAdmin;
